import { ScrollArea } from "@mantine/core";
import React, { useEffect, useRef } from "react";
import { useGetMessagesQuery } from "../../../store/api/chat/chat.api";
import { supabase } from "../../../supabase";
import Message from "./Message";

type Props = {
    id: string;
};

const Messages = ({ id }: Props) => {
    const scrollViewPoint = useRef<HTMLDivElement | null>(null);
    const { data: messages, refetch } = useGetMessagesQuery(id);
    const scrollToBottom = () => {
        scrollViewPoint.current?.scrollTo({ top: scrollViewPoint.current.scrollHeight, behavior: "smooth" });
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    useEffect(() => {
        // for now lets leave it like that, refetching entire chat on every message
        // this should be in rtk query function, i cant figure it out their docs are outdated
        const channel = supabase.channel("db-messages");
        channel.on(
            "postgres_changes",
            {
                event: "INSERT",
                schema: "public",
                table: "message",
                filter: `chat_id=eq.${id}`,
            },
            () => {
                refetch();
            }
        );

        const subscribtion = channel.subscribe();
        return () => {
            subscribtion.unsubscribe();
        };
    }, []);
    return (
        <ScrollArea viewportRef={scrollViewPoint} className="h-10/12 px-5 pt-2 flex justify-end">
            <div className="flex flex-col">
                <div className="text-center">
                    <span className="text-gray-400 mb-10">
                        This is the start of your messages. Remember to act profesionally and be patient - you cannot
                        delete or edit messages. <br />
                        Only you, the person you are talking with and the staff have acces to your messages.
                    </span>
                </div>
                {messages?.map((item) => (
                    <Message
                        key={item.id}
                        sender={item.profiles.username || item.profiles.company_info?.company_name || "Unnamed"}
                        date={item.created_at.toString()}
                        avatar_url={item.profiles.avatar_url}
                        text={item.message}
                    />
                ))}
            </div>
        </ScrollArea>
    );
};

export default Messages;
