import { Center, createStyles } from "@mantine/core";
import AcceptedPartnerships from "./components/AcceptedPartnerships";
import PendingPartnerships from "./components/PendingPartnerships";
import Loading from "../../components/loading/Loading";

const useStyles = createStyles((theme) => ({
    container: {
        margin: "auto",
        marginTop: "3rem",
        width: "32rem",

        "@media (max-width: 740px)": {
            width: "20rem",
        },
    },

    divider: {
        borderLeft: "1px solid #b5b8bf",
        opacity: "50%",
        marginTop: "3rem",
        height: "500px",

        "@media (max-width: 1185px)": {
            display: "none",
        },
    },
}));

const Partnerships = () => {
    const { classes } = useStyles();
    return (
        <Center className="flex flex-col">
            <p className="mt-3 text-4xl font-bold">My partnerships</p>
            <div className="flex flex-row flex-wrap gap-20">
                <div className={classes.container}>
                    <p className="text-center text-xl text-medium font-semibold">Accepted</p>
                    <AcceptedPartnerships />
                </div>
                <div className={classes.divider} />
                <div className={classes.container}>
                    <p className="text-center text-xl text-medium font-semibold">Invitations</p>
                    <PendingPartnerships />
                </div>
            </div>
        </Center>
    );
};

export default Partnerships;
