import React from "react";
import { Navigate, Outlet } from "react-router";
import { useGetUserQuery } from "../store/api/auth/authApi.api";

const InfluencerRoute = () => {
	const profile = useGetUserQuery();
	return profile.data?.account_type === "INFLUENCER" ? <Outlet /> : <Navigate replace to={"/not_found"} />;
};

export default InfluencerRoute;
