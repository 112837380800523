import { useState } from "react";
import { Navigate } from "react-router-dom";

import { useGetUserQuery } from "../../store/api/auth/authApi.api";
import { useAppSelector } from "../../store/hooks";
import { selectAccountType } from "../../store/reducers/AccountInformation/AccountInformationSlice";
import AccountTypeComponent from "./components/AccountType";
import CompanyStepper from "./components/CompanyStepper";
import InfluencerStepper from "./components/InfluencerStepper";

const CompleteInformationPage = () => {
	const accountType = useAppSelector(selectAccountType);
	const { data: user } = useGetUserQuery();
	const [startForm, setStartForm] = useState(false);
	if (user?.account_type !== null) {
		return <Navigate to={"/"} />;
	}
	return (
		<div className="w-full h-full flex justify-center pt-10">
			<AccountTypeComponent selectedType={accountType} handleNext={() => setStartForm(true)} hidden={startForm} />
			{startForm &&
				(accountType === "COMPANY" ? (
					<CompanyStepper handleChangeType={() => setStartForm(false)} />
				) : (
					<InfluencerStepper handleChangeType={() => setStartForm(false)} />
				))}
		</div>
	);
};

export default CompleteInformationPage;
