import { Select } from "@mantine/core";
import React from "react";
import { Control, Controller } from "react-hook-form";
import ContractType from "../../../data/ContractType";
import Offer from "../../../types/Offer";

type Props = {
    control: Control<Offer, unknown>;
};

const ContractTypeStep = ({ control }: Props) => {
    return (
        <>
            <Controller
                name="contract_type"
                control={control}
                render={({ field }) => (
                    <Select
                        label="Contract Type"
                        className="mb-5"
                        {...field}
                        data={[
                            ...Object.keys(ContractType).map((key) => {
                                return { value: key, label: Object(ContractType)[key] };
                            }),
                        ]}
                    ></Select>
                )}
            />
            <div className="h-[32rem]"></div>
        </>
    );
};

export default ContractTypeStep;
