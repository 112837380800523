import { Accordion, Button } from "@mantine/core";
import { useNavigate } from "react-router";
import ContractType from "../../../data/ContractType";
import { IconFileX } from "@tabler/icons";
import { useGetInfluencerCampaignsQuery } from "../../../store/api/campaign/campaign.api";

const AcceptedPartnerships = () => {
    const { data } = useGetInfluencerCampaignsQuery();
    const navigate = useNavigate();
    if (data?.length === 0) {
        return (
            <div className="flex flex-col items-center mt-4">
                <IconFileX width={64} height={64} stroke={0.7} color="#b5b8bf" />
                <p className="text-gray-500 text-opacity-50 text-center">You haven't accepted any partnerships yet.</p>
            </div>
        );
    }
    return (
        <>
            <Accordion variant="separated" radius="md" defaultValue="customization">
                {data?.map((item) => (
                    <Accordion.Item key={item.id} value={item.id?.toString() || "-1"} className="mt-4">
                        <Accordion.Control>
                            <p className="text-xl">{item.campaign.campaign_name}</p>
                            <p className="text-opacity-70 text-black">{item.description}</p>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <p className="text-opacity-60 text-gray-500">
                                <span className="text-black">Proposed Pay: </span> {item.proposed_pay}$
                            </p>
                            <p className="text-opacity-60 text-gray-500">
                                <span className="text-black">Contract type: </span>
                                {ContractType[item.contract_type]}
                            </p>
                            <p className="text-opacity-60 text-gray-500">
                                <span className="text-black">Additional Information: </span>
                                {item.additional_info}
                            </p>
                            <div>
                                <Button
                                    variant="light"
                                    className="mt-2"
                                    onClick={() => navigate(`/chat/${item.campaign.chat[0].id}`)}
                                >
                                    Open Chat
                                </Button>
                            </div>
                        </Accordion.Panel>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
};

export default AcceptedPartnerships;
