import React, { useRef } from "react";
import Newsletter from "../../components/newsletter/Newsletter";
import Feature from "./components/Feature";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";

const LandingPage = () => {
    const contactRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    return (
        <div className="w-full">
            <Header contactRef={contactRef} />
            <Feature />
            <Contact ref={contactRef} />
            <Footer />
            <Newsletter />
        </div>
    );
};

export default LandingPage;
