import { createStyles, Title, Text, Button, Container, Divider } from "@mantine/core";
import { Dots } from "./Dots";
import { useNavigate } from "react-router";

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: "relative",
        paddingTop: 120,
        paddingBottom: 80,

        "@media (max-width: 755px)": {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    inner: {
        position: "relative",
        zIndex: 1,
    },

    dots: {
        position: "absolute",
        color: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1],

        "@media (max-width: 755px)": {
            display: "none",
        },
    },

    dotsLeft: {
        left: 0,
        top: 0,
    },

    title: {
        textAlign: "center",
        fontWeight: 800,
        fontSize: 40,
        letterSpacing: -1,
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
        marginBottom: theme.spacing.xs,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,

        "@media (max-width: 520px)": {
            fontSize: 28,
            textAlign: "left",
        },
    },

    header: {
        textAlign: "center",
        fontWeight: 600,
        fontSize: 28,
        letterSpacing: -1,
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
        marginBottom: theme.spacing.xs,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    highlight: {
        color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
    },

    description: {
        textAlign: "center",

        "@media (max-width: 520px)": {
            textAlign: "left",
            fontSize: theme.fontSizes.md,
        },
    },

    controls: {
        marginTop: theme.spacing.lg,
        display: "flex",
        justifyContent: "center",

        "@media (max-width: 520px)": {
            flexDirection: "column",
        },
    },

    control: {
        "&:not(:first-of-type)": {
            marginLeft: theme.spacing.md,
        },

        "@media (max-width: 520px)": {
            height: 42,
            fontSize: theme.fontSizes.md,

            "&:not(:first-of-type)": {
                marginTop: theme.spacing.md,
                marginLeft: 0,
            },
        },
    },
}));

const Header = ({ contactRef }: { contactRef: any }) => {
    const { classes } = useStyles();
    const navigate = useNavigate();

    const handleClick = () => {
        contactRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <>
            <Container className={classes.wrapper} size={1400}>
                <Dots className={classes.dots} style={{ left: 0, top: 5 }} />
                <Dots className={classes.dots} style={{ left: 60, top: 5 }} />
                <Dots className={classes.dots} style={{ left: 0, top: 145 }} />
                <Dots className={classes.dots} style={{ right: 0, top: 60 }} />
                <Dots className={classes.dots} style={{ right: 0, top: 260 }} />

                <div className={classes.inner}>
                    <Text className={classes.header}>DealFluence</Text>
                    <Title className={classes.title}>
                        A brand new way to{" "}
                        <Text component="span" className={classes.highlight} inherit>
                            monetize{" "}
                        </Text>
                        your reach
                    </Title>

                    <Container p={0} size={600}>
                        <Text size="lg" color="dimmed" className={classes.description}>
                            Dealfluence is an innovative, completely free online platform that streamlines every step of
                            the processes of commissioning, creation, and delivery of sponsored content in social media.
                        </Text>
                    </Container>

                    <div className={classes.controls}>
                        <Button
                            className={classes.control}
                            size="lg"
                            variant="default"
                            color="gray"
                            onClick={() => handleClick()}
                        >
                            Contact Us
                        </Button>
                        <Button className={classes.control} size="lg" onClick={() => navigate("/register")}>
                            Create an account
                        </Button>
                    </div>
                </div>
            </Container>
            <Divider mx={210} className="opacity-50" />
        </>
    );
};

export default Header;
