import { getUser } from "./../../../utils/authUtils";
import { MessageInsert } from "./chat.types";
import supabaseApi from "..";
import { supabase } from "../../../supabase";
import Message from "../../../types/Message";

const chatApi = supabaseApi.injectEndpoints({
    endpoints: (builder) => ({
        // supabase rtk query enpoints for authentication
        getMessages: builder.query<Message[], string>({
            queryFn: async (chatId) => {
                const messagesQuery = `
                id,
                message,
                profiles(id,account_type, avatar_url, username, influencer_info(name, last_name), company_info(company_name)),
                created_at
                `;
                const { data, error: messagesError } = await supabase
                    .from("message")
                    .select(messagesQuery)
                    .eq("chat_id", chatId)
                    .order("created_at", { ascending: true });
                if (messagesError) {
                    return { error: messagesError?.message };
                }
                return { data: data as Message[] };
            },
        }),
        sendMessage: builder.mutation<void, MessageInsert>({
            queryFn: async (message) => {
                const { user, error: userError } = await getUser();
                if (userError) {
                    return { error: userError.message };
                }
                const { error: messageError } = await supabase
                    .from("message")
                    .insert({ chat_id: message.chat_id, message: message.message, user_id: user.id });
                if (messageError) {
                    return { error: messageError?.message };
                }
                return { data: undefined };
            },
        }),
    }),
});
export const { useGetMessagesQuery, useSendMessageMutation } = chatApi;
export { chatApi };
