import { Grid } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import UserCard from "../../../components/user/UserCard";
import { useLazyGetRecommendedInfluencersQuery } from "../../../store/api/campaign/campaign.api";
import Campaign, { Influencer } from "../../../store/api/campaign/campaign.types";
import MakeOfferModal from "./MakeOfferModal";

export const AddInfluencersPanel = ({ campaign }: { campaign: Campaign }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [fetchInfluencers, { data, isLoading }] = useLazyGetRecommendedInfluencersQuery();
    const [selectedUser, setSelectedUser] = useState<Influencer>({} as Influencer);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (id) {
            fetchInfluencers(id);
        } else {
            navigate("/not_found");
        }
    }, [id]);
    if (isLoading || !data) {
        return <div>Loading...</div>;
    }

    const handleOffer = (user: Influencer) => {
        setSelectedUser(user);
        setOpen(true);
    };
    return (
        <>
            <Grid>
                {data.map((user: Influencer) => (
                    <Grid.Col key={user.id} lg={3} md={4} sm={6} xs={12}>
                        <UserCard
                            image={
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Paracas_National_Reserve%2C_Ica%2C_Peru-3April2011.jpg/1200px-Paracas_National_Reserve%2C_Ica%2C_Peru-3April2011.jpg"
                            }
                            avatar={user.avatar_url}
                            stats={[
                                {
                                    label: "10k Followers",
                                    value: "Tiktok",
                                },
                                {
                                    label: "10k Likes",
                                    value: "Instagram",
                                },
                                {
                                    label: "90k Subscribers",
                                    value: "Youtube",
                                },
                            ]}
                            handleOffer={handleOffer}
                            user={user}
                        />
                    </Grid.Col>
                ))}
            </Grid>
            <MakeOfferModal campaign={campaign} user={selectedUser} open={open} setOpen={setOpen} />
        </>
    );
};
