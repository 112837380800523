import React from "react";

type Props = {
	children: JSX.Element[] | JSX.Element;
	lg?: boolean;
};

const Layout = ({ children, lg }: Props) => {
	return <main className={"w-full mt-5 justify-center flex flex-col mx-auto " + (lg ? "max-w-screen-xl" : "max-w-screen-lg")}>{children}</main>;
};

export default Layout;
