import supabaseApi from "..";
import { supabase } from "../../../supabase";
import { isInfluencerData } from "../../../types/typeguards/typeguards";
import { getUser } from "../../../utils/authUtils";
import InfluencerProfiles, { InfluencerDetails } from "./../../../types/InfluencerInformation.types";
import { CompanyInformation } from "./../../reducers/AccountInformation/AccountInformation.types";
import {
    AccountInformationUpdateParams,
    InfluencerInformation,
    InfluencerInformationUpdate,
} from "./accountInformation.types";

const accountInformationApi = supabaseApi.injectEndpoints({
    endpoints: (builder) => ({
        // supabase rtk query enpoints for authentication
        getInfluencerInformation: builder.query<InfluencerInformation, void>({
            queryFn: async () => {
                const { user, error: userError } = await getUser();
                if (userError) {
                    return { error: userError };
                }
                const selectQueryInfluencer = `
				id,
                username,
				influencer_info (
					id, 
					name,
					last_name,
					target_audience(*),
					content_nieche,
					influencer_profiles:social_profile(*)
					)`;
                const { data, error: influencerError } = await supabase
                    .from("profiles")
                    .select(selectQueryInfluencer)
                    .eq("id", user?.id)
                    .single();
                if (influencerError) {
                    return { error: influencerError };
                }
                return { data: data as InfluencerInformation };
            },
            providesTags: ["UserInformation"],
        }),
        getCompanyInformation: builder.query<CompanyInformation, void>({
            queryFn: async () => {
                const { user, error: userError } = await getUser();
                if (userError) {
                    return { error: userError.message };
                }
                const selectQueryCompany = `
				id,
				company_info (
						 company_name,
						 nip,
						 registration_date,
						 legal_entity_type
						)`;

                // TODO: split it into two queries
                const { data, error } = await supabase
                    .from("profiles")
                    .select(selectQueryCompany)
                    .eq("id", user?.id)
                    .single();
                if (error) {
                    return { error: error?.message };
                }
                return { data: data as CompanyInformation };
            },
            providesTags: ["UserInformation"],
        }),
        getInfluencerInformationById: builder.query<InfluencerDetails, string>({
            queryFn: async (id) => {
                const profileQuery = `			
                id,
                username,
                avatar_url,
				influencer_info (
					id, 
					name,
					last_name,
					target_audience(*),
					content_nieche,
					influencer_profiles:social_profile(*)
					)`;
                const { data, error } = await supabase.from("profiles").select(profileQuery).eq("id", id).single();
                if (error) {
                    return { error: error };
                }
                return { data: data as InfluencerDetails };
            },
            providesTags: ["UserInformation"],
        }),
        postInformation: builder.mutation<null, AccountInformationUpdateParams>({
            queryFn: async (params) => {
                const { user, error: userError } = await getUser();

                if (userError) {
                    return { error: userError.message };
                }
                if (params.accountType === "INFLUENCER") {
                    if (!isInfluencerData(params.data)) {
                        return { error: "Wrong data type" };
                    }
                    const { data: targetAudienceData, error: targetAudienceError } = await supabase
                        .from("target_audience")
                        .insert([{ ...params.data.target_audience }])
                        .select("id");
                    if (targetAudienceError) {
                        return { error: targetAudienceError?.message };
                    }

                    const { data, error } = await supabase
                        .from("influencer_info")
                        .insert([
                            {
                                name: params.data.name,
                                last_name: params.data.last_name,
                                content_nieche: params.data.content_nieche,
                                target_audience_id: targetAudienceData?.[0].id,
                            },
                        ])
                        .select("id")
                        .single();
                    if (error) {
                        return { error: error?.message };
                    }
                    const { error: profileError } = await supabase
                        .from("profiles")
                        .update([{ influencer_info_id: data?.id, account_type: params.accountType }])
                        .eq("id", user.id);

                    if (profileError) {
                        return { error: profileError?.message };
                    }
                } else {
                    if (isInfluencerData(params.data)) {
                        return { error: "Wrong data type" };
                    }
                    const { data, error } = await supabase
                        .from("company_info")
                        .insert([
                            {
                                ...params.data,
                            },
                        ])
                        .select("id")
                        .single();
                    if (error) {
                        return { error: error?.message };
                    }
                    const { error: profileError } = await supabase
                        .from("profiles")
                        .update([{ company_info_id: data?.id, account_type: params.accountType }])
                        .eq("id", user.id);

                    if (profileError) {
                        return { error: profileError?.message };
                    }
                }
                return { data: null };
            },
            invalidatesTags: ["UserInformation", "User"],
        }),
        updateInfluencerInformation: builder.mutation<null, InfluencerInformationUpdate>({
            queryFn: async (params) => {
                const { user, error: userError } = await getUser();

                if (userError) {
                    return { error: userError.message };
                }
                const { data: profileData, error: profileError } = await supabase
                    .from("profiles")
                    .update([{ username: params.username, avatar_url: params.avatar_url }])
                    .eq("id", user.id)
                    .select("influencer_info_id")
                    .single();

                if (profileError) {
                    return { error: profileError?.message };
                }
                const { data: influencerInfoData, error: infoError } = await supabase
                    .from("influencer_info")
                    .update([
                        {
                            name: params.name,
                            last_name: params.last_name,
                            content_nieche: params.content_nieche,
                        },
                    ])
                    .eq("id", profileData?.influencer_info_id)
                    .select("*")
                    .single();

                if (infoError) {
                    return { error: infoError?.message };
                }
                const { error: targetAudienceError } = await supabase
                    .from("target_audience")
                    .update([{ ...params.target_audience }])
                    .eq("id", influencerInfoData?.target_audience_id);

                if (targetAudienceError) {
                    return { error: targetAudienceError?.message };
                }

                return { data: null };
            },
            invalidatesTags: ["UserInformation", "User"],
        }),
        addSocialPlatform: builder.mutation<null, InfluencerProfiles & { influencer_info_id?: number }>({
            queryFn: async (params) => {
                const { error: userError } = await getUser();
                if (userError) {
                    return { error: userError.message };
                }
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { influencer_info_id, ...profileValues } = params;
                const { data: profileData, error: profileError } = await supabase
                    .from("social_profile")
                    .insert([{ ...profileValues }])
                    .select("id")
                    .single();
                if (profileError) {
                    return { error: profileError.message };
                }
                const { error: influencerProfileError } = await supabase
                    .from("influencer_profiles")
                    .insert([{ social_profile_id: profileData?.id, influencer_info_id: params.influencer_info_id }]);
                if (influencerProfileError) {
                    return { error: influencerProfileError.message };
                }
                return { data: null };
            },
            invalidatesTags: ["UserInformation"],
        }),
        updateAvatar: builder.mutation<null, { avatarImg: File }>({
            queryFn: async ({ avatarImg }) => {
                const { user, error: userError } = await getUser();
                if (userError) {
                    return { error: userError.message };
                }

                const { data, error } = await supabase.storage
                    .from("avatars")
                    .upload(`public/${user.id}.jpg`, avatarImg, { upsert: true });
                if (error) {
                    return { error: error.message };
                }
                const { data: dataUrl, error: errorUrl } = await supabase.storage
                    .from("avatars")
                    .createSignedUrl(data.path, 3600000);
                if (errorUrl) {
                    return { error: errorUrl.message };
                }
                const { error: profileError } = await supabase
                    .from("profiles")
                    .update([{ avatar_url: dataUrl?.signedUrl }])
                    .eq("id", user.id);
                if (profileError) {
                    return { error: profileError.message };
                }
                return { data: null };
            },
            invalidatesTags: ["UserInformation"],
        }),
    }),
});
export const {
    useGetCompanyInformationQuery,
    useGetInfluencerInformationQuery,
    usePostInformationMutation,
    useLazyGetInfluencerInformationByIdQuery,
    useUpdateInfluencerInformationMutation,
    useAddSocialPlatformMutation,
    useUpdateAvatarMutation,
} = accountInformationApi;
export { accountInformationApi };
