import { IconBuilding, IconChevronRight, IconUser } from "@tabler/icons";
import React from "react";
import { useAppDispatch } from "../../../store/hooks";
import { setAccountType } from "../../../store/reducers/AccountInformation/AccountInformationSlice";
import { AccountType } from "../../../types/enums.types";
type Props = {
	selectedType: AccountType | null;
	handleNext: () => void;
	hidden: boolean;
};
const AccountTypeComponent = ({ selectedType, handleNext, hidden }: Props) => {
	const dispatch = useAppDispatch();
	const handleAccountTypeChange = (type: AccountType) => {
		dispatch(setAccountType(type));
	};
	return (
		<div className={"md:w-1/2 w-full mt-10 max-w-lg h-full"} hidden={hidden}>
			<div className={"w-full rounded-lg bg-white flex flex-col gap-5 justify-center p-5 shadow-md shadow-blue-100"}>
				<h1 className="text-xl text-slate-800 font-bold text-center">Please pick your account type</h1>
				<div className="flex flex-row gap-5 h-full">
					<div
						onClick={() => handleAccountTypeChange("COMPANY")}
						className={
							"w-full bg-background rounded-lg h-full hover:scale-95 transition-transform flex items-center p-3" +
							(selectedType === "COMPANY" ? " border-2 border-blue-500" : "")
						}
					>
						<IconBuilding fontSize={"large"} />
						<p className="text-lg font-semibold">Company</p>
					</div>
					<div
						onClick={() => handleAccountTypeChange("INFLUENCER")}
						className={
							"bg-background rounded-lg h-full w-full  hover:scale-95 transition-transform flex items-center p-3" +
							(selectedType === "INFLUENCER" ? " border-2 border-blue-500 border-collapse" : "")
						}
					>
						<IconUser fontSize="large" />
						<p className="text-lg font-semibold">Influencer</p>
					</div>
				</div>
				<div className="ml-auto px-3 text-lg flex flex-row gap-10">
					<button onClick={() => handleNext()} disabled={!selectedType} className="flex items-center disabled:text-slate-500">
						Next <IconChevronRight />
					</button>
				</div>
			</div>
		</div>
	);
};

export default AccountTypeComponent;
