import { yupResolver } from "@hookform/resolvers/yup";
import { Button, PasswordInput, TextInput } from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useSignUpMutation } from "../../store/api/auth/authApi.api";
interface RegisterData {
    username: string;
    email: string;
    password: string;
    repeatPassword: string;
}
const RegisterSchema = Yup.object().shape({
    username: Yup.string().min(3, "Too Short!").max(20, "Too Long!").required("Required"),
    email: Yup.string().email("Email is invalid").required("Required"),
    password: Yup.string().min(6, "Too Short!").max(33, "Too Long!").required("Required"),
    repeatPassword: Yup.string()
        .min(6, "Too Short!")
        .max(33, "Too Long!")
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Passwords don't match!"),
});
const Register = () => {
    const navigate = useNavigate();
    const [register, { isLoading }] = useSignUpMutation();
    const { handleSubmit, formState, control } = useForm<RegisterData>({
        resolver: yupResolver(RegisterSchema),
        defaultValues: {
            username: "",
            email: "",
            password: "",
            repeatPassword: "",
        },
    });
    const onSubmit = (data: RegisterData) => {
        register({ ...data })
            .unwrap()
            .then(() => {
                toast.success("Registered successfully!");
                navigate("/login");
            })
            .catch((err) => {
                toast.error(err);
            });
    };
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full h-[42rem] max-w-md flex flex-col rounded-xl min-w-sm justify-center shadow-stone-300 py-5 px-16 bg-white text-slate-800"
        >
            <div className="w-full relative mb-6">
                <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                        <TextInput
                            autoComplete="username"
                            placeholder="Your Username"
                            label="Username"
                            error={formState.errors["username"]?.message}
                            {...field}
                        />
                    )}
                />
            </div>
            <div className="w-full relative mb-6">
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextInput
                            label="Email"
                            placeholder="example@mail.com"
                            error={formState.errors["email"]?.message}
                            {...field}
                        />
                    )}
                />
            </div>
            <div className="w-full relative mb-7">
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <PasswordInput
                            autoComplete="new-password"
                            label="Password"
                            placeholder="••••••••"
                            error={formState.errors["password"]?.message}
                            {...field}
                        />
                    )}
                />
            </div>
            <div className="w-full relative mb-7">
                <Controller
                    name="repeatPassword"
                    control={control}
                    render={({ field }) => (
                        <PasswordInput
                            autoComplete="new-password"
                            label="Confirm Password"
                            placeholder="••••••••"
                            error={formState.errors["repeatPassword"]?.message}
                            {...field}
                        />
                    )}
                />
            </div>

            <Button type="submit" radius="md" className="mb-10" loading={isLoading}>
                Sign Up
            </Button>
            <p className="text-sm">
                Already have an account? <Link to="/login">Sign In</Link>
            </p>
        </form>
    );
};

export default Register;
