import { yupResolver } from "@hookform/resolvers/yup";
import { Select, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import LegalEntityType from "../../../data/LegalEnttityType";
import {
    selectCompanyInformation,
    setCompanyInformation,
} from "../../../store/reducers/AccountInformation/AccountInformationSlice";
import { CompanyInformationData } from "../../../store/reducers/AccountInformation/AccountInformation.types";
import PageWrapper from "./PageWrapper";

type Props = {
    handleNext: () => void;
    handlePrev: () => void;
};
const CompanySchema = Yup.object().shape({
    company_name: Yup.string().min(2, "Too Short!").max(120, "Too Long!").required("Required"),
    nip: Yup.string().length(10, "Enter valid nip").required("Required"),
    legal_entity_type: Yup.string().required("Required"),
    registration_date: Yup.date().max(new Date(), "Registration date has to be in the past").required("Required"),
});
const CompanyInfo = ({ handleNext, handlePrev }: Props) => {
    const dispatch = useAppDispatch();
    const values = useAppSelector(selectCompanyInformation);
    const { handleSubmit, formState, control } = useForm<CompanyInformationData>({
        resolver: yupResolver(CompanySchema),
        defaultValues: { ...values },
    });
    const submit = useRef<HTMLFormElement>(null);
    const onSubmit = (data: CompanyInformationData) => {
        dispatch(
            setCompanyInformation({
                ...data,
                registration_date: new Date(data.registration_date.toString()).toISOString(),
            })
        );
        handleNext();
    };
    const requestSubmit = () => {
        submit.current?.requestSubmit();
    };
    return (
        <PageWrapper handlePrev={handlePrev} handleSubmit={requestSubmit}>
            <h1 className="text-xl text-slate-800 font-bold text-center">Fill out your profile informations</h1>
            <form
                ref={submit}
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-5 h-full mt-5 justify-center px-4"
            >
                <div className="flex flex-row gap-5 h-full mt-5">
                    <div className="w-full relative mb-6">
                        <Controller
                            name="company_name"
                            control={control}
                            render={({ field }) => (
                                <TextInput
                                    withAsterisk
                                    label="Company Name"
                                    error={formState.errors["company_name"]?.message}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className="w-full relative mb-6">
                        <Controller
                            name="nip"
                            control={control}
                            render={({ field }) => (
                                <TextInput
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    withAsterisk
                                    label="NIP"
                                    error={formState.errors["nip"]?.message}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="w-full relative mb-6 flex flex-col">
                    <Controller
                        name="legal_entity_type"
                        control={control}
                        render={({ field }) => (
                            <Select
                                error={formState.errors["legal_entity_type"]?.message}
                                {...field}
                                label="Legal Entity Type"
                                data={[
                                    ...Object.keys(LegalEntityType).map((key) => {
                                        return { value: key, label: Object(LegalEntityType)[key] };
                                    }),
                                ]}
                            ></Select>
                        )}
                    />
                </div>
                <div className="w-full relative mb-6 flex flex-col">
                    <Controller
                        name="registration_date"
                        control={control}
                        render={({ field }) => (
                            // zmiana daty ze stringa na date wymaga zrobienia tego tak
                            <DatePicker
                                value={new Date(field.value)}
                                error={formState.errors["registration_date"]?.message}
                                ref={field.ref}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                name={field.name}
                                label="Registration Date"
                            ></DatePicker>
                        )}
                    />
                </div>
            </form>
        </PageWrapper>
    );
};
export default CompanyInfo;
