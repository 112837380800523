import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextInput } from "@mantine/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as Yup from "yup";

interface ForgotPasswordData {
	email: string;
}

const ResetSchema = Yup.object().shape({
	email: Yup.string().email("Email is invalid").required("Required"),
});

const ForgotPassword = () => {
	const { handleSubmit, formState, control } = useForm<ForgotPasswordData>({
		resolver: yupResolver(ResetSchema),
		defaultValues: {
			email: "",
		},
	});
	const onSubmit = (data: ForgotPasswordData) => {
		// send, user will get link with embedded confirmation code
	};
	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="w-full h-[28rem] max-w-md flex flex-col rounded-xl min-w-sm justify-center shadow-stone-300 shadow-2xl px-16 bg-white text-slate-800"
		>
			<h1 className="text-3xl font-semibold text-center mt-4 mb-6">Password Reset</h1>
			<p className="text-center text-base">
				An e-mail containing instructions to reseting your password will be sent the e-mail adress that you enter below.
			</p>
			<p className="text-center text-base pt-5">Be patient, it may take between 2-5 minutes.</p>
			<Controller
				name="email"
				control={control}
				render={({ field }) => <TextInput label="Email" placeholder="example@mail.com" error={formState.errors["email"]?.message} {...field} />}
			/>

			<Button type={"submit"} radius="md" className="my-5">
				Reset my password
			</Button>
			<p className="text-sm text-center">
				Already have an account?
				<Link to="/login">
					<span className="hover:italic">Sign in</span>
				</Link>
			</p>
		</form>
	);
};

export default ForgotPassword;
