import React from "react";
import ReactLoading from "react-loading";

const Loading = () => {
    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <ReactLoading type={"spinningBubbles"} color={"#000"} height={"10%"} width={"10%"} />
        </div>
    );
};

export default Loading;
