import React from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import Loading from "../../components/loading/Loading";

import Layout from "../../layouts/Layout";
import { useLazyGetCampaignByIdQuery } from "../../store/api/campaign/campaign.api";
import EditLayout from "./components/EditLayout";

const EditCampaign = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    // check for ownership
    const [fetchCampaign, { data: campaign, isLoading, error }] = useLazyGetCampaignByIdQuery();

    React.useEffect(() => {
        if (id) {
            fetchCampaign(id);
        } else {
            navigate("/not_found");
        }
    }, [id]);
    if (isLoading || !campaign) {
        return <Loading />;
    }
    if (error) {
        return <Navigate to={"/not_found"} />;
    }
    return (
        <Layout>
            <EditLayout campaign={campaign} />
        </Layout>
    );
};

export default EditCampaign;
