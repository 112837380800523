import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useGetUserQuery } from "../../store/api/auth/authApi.api";

type Props = {
	children: React.ReactNode;
};

const RedirectProvider = ({ children }: Props) => {
	const { data, isLoading } = useGetUserQuery();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isLoading && !!data && data.account_type === null) {
			navigate("/complete_information");
		}
	}, [pathname]);

	return <>{children}</>;
};

export default RedirectProvider;
