import { Alert, Button, Divider, Group, MultiSelect, NumberInput, Select, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { IconAlertCircle } from "@tabler/icons";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Loading from "../../../components/loading/Loading";
import ContentNieche from "../../../data/ContentNieche";
import {
    useGetInfluencerInformationQuery,
    useUpdateInfluencerInformationMutation,
} from "../../../store/api/completeInformation/accountInformation.api";
import { InfluencerInformationUpdate } from "../../../store/api/completeInformation/accountInformation.types";
import { useGetCountriesQuery } from "../../../store/api/countries/countriesApi";
import GenderDistribution from "../../../types/GenderDistribution";

const InfluencerEdit = () => {
    const navigate = useNavigate();
    const InfluencerEditSchema = Yup.object().shape({
        name: Yup.string().min(2, "Too Short!").max(33, "Too Long!").required("Required"),
        last_name: Yup.string().min(2, "Too Short!").max(33, "Too Long!").required("Required"),
        username: Yup.string().min(2, "Too Short!").max(33, "Too Long!").required("Required"),
        content_nieche: Yup.array().min(1, "Pick at least one").required("Required"),
        target_audience: Yup.object().shape({
            minAge: Yup.number().min(2, "Too low!").max(100, "Too High!").required("Required"),
            maxAge: Yup.number()
                .min(Yup.ref("minAge"), "Maximum Age Cant be lower!")
                .max(100, "Too High!")
                .required("Required"),
            destinationCountry: Yup.string().required("Required"),
            gender_distribution: Yup.string().required("Required"),
        }),
    });
    const { data: user, isLoading } = useGetInfluencerInformationQuery();
    const [updateInfluencer] = useUpdateInfluencerInformationMutation();
    const countries = useGetCountriesQuery();
    const countriesValues = useMemo(
        () =>
            countries.data?.map((country) => ({ label: country.name || "Unnamed", value: country.id.toString() })) ||
            [],
        [countries]
    );
    const form = useForm<InfluencerInformationUpdate>({
        validate: yupResolver(InfluencerEditSchema),
        initialValues: {
            name: "",
            last_name: "",
            username: "",
            content_nieche: [],
            target_audience: {
                minAge: 0,
                maxAge: 0,
                destinationCountry: "",
                gender_distribution: "MAINLY_MAN",
            },
        },
    });
    useEffect(() => {
        if (!isLoading && user) {
            // cant spread for some reason
            form.setValues({
                name: user.influencer_info.name,
                last_name: user.influencer_info.last_name,
                username: user.username,
                content_nieche: user.influencer_info.content_nieche,
                target_audience: user.influencer_info.target_audience,
            });
        }
    }, [user]);
    if (isLoading) return <Loading />;
    if (!user) {
        return (
            <p>
                <Alert icon={<IconAlertCircle size={16} />} title="Oops!" color="red">
                    Something went wrong! Try reloading your page!
                </Alert>
            </p>
        );
    }

    const onSubmit = (data: InfluencerInformationUpdate) => {
        updateInfluencer(data)
            .then(() => {
                navigate("/influencer/" + user.id);
            })
            .catch((err) => toast.error(err));
    };

    return (
        <div className="w-4/6 mt-5 justify-center flex flex-col mx-auto ">
            <p className="text-4xl font-medium"> Edit Account Information</p>
            <Divider my="sm" />
            <form onSubmit={form.onSubmit(onSubmit)} className="flex flex-col gap-5 h-full mt-5 justify-center px-4">
                <TextInput label="Username" placeholder="Your username" {...form.getInputProps("username")} />
                <div className="flex flex-row gap-5 h-full mt-5">
                    <div className="w-full relative mb-6">
                        <TextInput label="Name" placeholder="Your name" {...form.getInputProps("name")} />
                    </div>
                    <div className="w-full relative mb-6">
                        <TextInput
                            label="Last Name"
                            placeholder="Your last name"
                            {...form.getInputProps("last_name")}
                        />
                    </div>
                </div>
                <div className="w-full relative mb-6 flex flex-col">
                    <MultiSelect
                        {...form.getInputProps("content_nieche")}
                        label="Content Nieche"
                        data={[
                            ...Object.values(ContentNieche).map((val) => {
                                return { value: val, label: val };
                            }),
                        ]}
                    />
                    {/* ----------------------------------- */}
                    <p className="text-4xl font-medium mt-5"> Edit Audience</p>
                    <Divider my="sm" />
                    <Select
                        label="Audience Country"
                        {...form.getInputProps("target_audience.destinationCountry")}
                        searchable
                        data={[...countriesValues]}
                    />
                </div>
                <div className="w-full relative mb-6 flex flex-col">
                    <Select
                        label="Gender Distribution"
                        {...form.getInputProps("target_audience.gender_distribution")}
                        data={[
                            ...Object.keys(GenderDistribution).map((val) => {
                                return { value: val, label: Object(GenderDistribution)[val] };
                            }),
                        ]}
                    />
                </div>
                <div className="flex flex-row gap-5 h-full mt-5">
                    <div className="w-full relative mb-6">
                        <NumberInput label="Minimum Age" {...form.getInputProps("target_audience.minAge")} />
                    </div>
                    <div className="w-full relative mb-6">
                        <NumberInput label="Maximum Age" {...form.getInputProps("target_audience.maxAge")} />
                    </div>
                </div>
                <Group position="right">
                    <Button type="submit" className="mb-5">
                        Confirm Changes
                    </Button>
                </Group>
            </form>
        </div>
    );
};
export default InfluencerEdit;
