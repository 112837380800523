import { Grid } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import UserCard from "../../../components/user/UserCard";
import { useLazyGetCampaignInfluencersQuery } from "../../../store/api/campaign/campaign.api";

export const HiredInfluencers = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [fetchInfluencers, { data, isLoading }] = useLazyGetCampaignInfluencersQuery();
    useEffect(() => {
        if (id) {
            fetchInfluencers(id);
        } else {
            navigate("/not_found");
        }
    }, [id]);
    if (isLoading || !data) {
        return <div>Loading...</div>;
    }
    const handleChat = (chatId: string) => {
        navigate("/chat/" + chatId);
    };
    return (
        <Grid>
            {data.map((user) => (
                <Grid.Col key={user.profiles.id} lg={3} md={4} sm={6} xs={12}>
                    <UserCard
                        image={
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Paracas_National_Reserve%2C_Ica%2C_Peru-3April2011.jpg/1200px-Paracas_National_Reserve%2C_Ica%2C_Peru-3April2011.jpg"
                        }
                        user={user.profiles}
                        avatar={user.profiles.avatar_url}
                        stats={[
                            {
                                label: "10k Followers",
                                value: "Tiktok",
                            },
                            {
                                label: "10k Likes",
                                value: "Instagram",
                            },
                            {
                                label: "90k Subscribers",
                                value: "Youtube",
                            },
                        ]}
                        handleChat={handleChat}
                    />
                </Grid.Col>
            ))}
        </Grid>
    );
};
