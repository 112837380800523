import React from "react";
import ForgotPassword from "../../components/authorization/ForgotPassword";

const ForgotPasswordPage = () => {
	return (
		<div className="w-full h-full overflow-hidden flex justify-center pt-20">
			<ForgotPassword />
		</div>
	);
};

export default ForgotPasswordPage;
