import React from "react";
import InfluencerAccount from "./components/InfluencerAccount";

const InfluencerAccountPage = () => {
    return (
        <div>
            <InfluencerAccount />
        </div>
    );
};

export default InfluencerAccountPage;
