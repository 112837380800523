const LegalEntityType = {
	JEDNOOSOBOWA_DZIALANOSC_GOSPODARCZA: "Jednoosobowa Działaność Gospodarcza",
	SPOLKA_CYWILNA: "Spółka Cywilna",
	SPOLKA_JAWNA: "Spółka Jawna",
	SPOLKA_PARTNERSKA: "Spółka Partnerska",
	SPOLKA_KOMANDYTOWA: "Spółka Komandytowa",
	SPOLKA_KOMANDYTOWO_AKCYJNA: "Spółka Komandytowo Akcyjna",
	SPOLKA_Z_OGRANICZONA_ODPOWIEDZIALNOSCIA: "Spółka z ograniczoną Odpowiedzialnością",
	SPOLKA_AKCYJNA: "Spółka Akcyjna",
};
export default LegalEntityType;
