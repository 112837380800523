import supabaseApi from "..";
import { supabase } from "../../../supabase";
import Offer from "../../../types/Offer";
import { getUser } from "../../../utils/authUtils";
import { TargetAudience } from "./../../../shared/types";
import Campaign, { CampaignInfluencer, CampaignInfluencerInsert, CampaignInsert, Influencer } from "./campaign.types";

const campaignApi = supabaseApi.injectEndpoints({
    endpoints: (builder) => ({
        // supabase rtk query enpoints for authentication
        getCampaigns: builder.query<Campaign[], void>({
            queryFn: async () => {
                const { user, error: userError } = await getUser();
                if (userError) {
                    return { error: userError };
                }
                const campaignQuery = `
				id,
                creator_id,
                campaign_name,
                campaign_description,
                ad_type,
                campaign_target,
                requirements,
                budget,
                target_audience(*),
                product_type,
				content_nieche
                `;
                const { data, error: campaignError } = await supabase
                    .from("campaign")
                    .select(campaignQuery)
                    .eq("creator_id", user?.id);
                if (campaignError) {
                    return { error: campaignError };
                }
                return { data };
            },
            providesTags: ["Campaign"],
        }),
        postCampaign: builder.mutation<Campaign, Campaign>({
            queryFn: async (params) => {
                const { user, error: userError } = await getUser();
                if (userError) {
                    return { error: userError.message };
                }
                const campaignParams: CampaignInsert = params;
                const targetAudienceParams: TargetAudience = params.target_audience;
                delete campaignParams.target_audience;

                if (params.id) {
                    const { error: targetAudienceError } = await supabase
                        .from("target_audience")
                        .update({ ...targetAudienceParams });

                    if (targetAudienceError) {
                        return { error: targetAudienceError };
                    }
                    const { data, error: campaignError } = await supabase
                        .from("campaign")
                        .update({ ...params })
                        .eq("id", params.id)
                        .select()
                        .single();
                    if (campaignError) {
                        return { error: campaignError?.message };
                    }
                    return { data };
                } else {
                    const { data: targetAudienceData, error: targetAudienceError } = await supabase
                        .from("target_audience")
                        .insert(targetAudienceParams)
                        .select("id")
                        .single();
                    if (targetAudienceError) {
                        return { error: targetAudienceError };
                    }
                    const { data, error: campaignError } = await supabase
                        .from("campaign")
                        .insert({ ...campaignParams, creator_id: user.id, target_audience_id: targetAudienceData.id })
                        .select()
                        .single();
                    if (campaignError) {
                        return { error: campaignError };
                    }
                    return { data };
                }
            },
            invalidatesTags: ["Campaign"],
        }),
        // TODO: CHANGE TTHE TYPE
        // ITS SUNDAY I JUST SPENT 3 HOURS TRYING TO FIGURE THE RETURN TYPE AND NOTHING IS WORKING
        // just keep it on any for now before i lose my mind
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getCampaignInfluencers: builder.query<CampaignInfluencer[], string>({
            queryFn: async (campaignId) => {
                const { error: userError } = await getUser();
                if (userError) {
                    return { error: userError };
                }
                const influencersQuery = `
                        profiles(
                            id,
                            username,
                            avatar_url,
                            influencer_info (
                                id, 
                                name,
                                last_name,
                                influencer_profiles:social_profile(*)
                            ),
                            user_chat(
                                chat!inner(id)
                            )
                        )
                `;
                const { data, error: campaignError } = await supabase
                    .from("campaign_influencers")
                    .select(influencersQuery)
                    .eq("campaign_id", campaignId)
                    .eq("profiles.user_chat.chat.campaign_id", campaignId);
                if (campaignError) {
                    return { error: campaignError };
                }
                return { data: data as CampaignInfluencer[] };
            },
            providesTags: ["CampaignInfluencers"],
        }),
        // ITS SUNDAY I JUST SPENT 3 HOURS TRYING TO FIGURE THE RETURN TYPE AND NOTHING IS WORKING
        // just keep it on any for now before i lose my mind
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getRecommendedInfluencers: builder.query<Influencer[], string>({
            queryFn: async (campaignId) => {
                const { error: userError } = await getUser();
                if (userError) {
                    return { error: userError };
                }
                // Cant find the easy way to filter through influencers not currently existing in the campaign, so this is the way for now.

                const influencersQuery = `
                    id,
                    username,
                    avatar_url,
                    alreadyInCampaign:campaign_influencers!campaign_influencers_user_id_fkey(id),
                    alreadyInvited:campaign_request!campaign_request_user_id_fkey(id),
                    influencer_info(
                        id, 
                        name,
                        last_name,
                        influencer_profiles:social_profile(*)
                    )
            `;
                const { data, error: campaignError } = await supabase
                    .from("profiles")
                    .select(influencersQuery)
                    .eq("account_type", "INFLUENCER")
                    .eq("alreadyInCampaign.campaign_id", campaignId)
                    .eq("alreadyInvited.campaign_id", campaignId);

                if (campaignError) {
                    return { error: campaignError };
                }
                return {
                    data: data?.filter((val) => {
                        return (
                            Array(val.alreadyInCampaign).flat().length === 0 &&
                            Array(val.alreadyInvited).flat().length === 0
                        );
                    }) as Influencer[],
                };
            },
            providesTags: ["CampaignInfluencers"],
        }),
        postCampaignInfluencers: builder.mutation<null, Offer>({
            queryFn: async (params) => {
                const { error: userError } = await getUser();
                if (userError) {
                    return { error: userError.message };
                }
                const { error: campaignError } = await supabase.from("campaign_request").insert({ ...params });
                if (campaignError) {
                    return { error: campaignError?.message };
                }
                return { data: null };
            },
            invalidatesTags: ["CampaignInfluencers"],
        }),
        postAcceptCampaignRequest: builder.mutation<null, CampaignInfluencerInsert>({
            queryFn: async ({ campaign_id }) => {
                const { user, error: userError } = await getUser();
                if (userError) {
                    return { error: userError.message };
                }
                const { error: campaignError } = await supabase
                    .from("campaign_influencers")
                    .insert({ user_id: user.id, campaign_id: campaign_id });
                if (campaignError) {
                    return { error: campaignError?.message };
                }
                const { data: campaignRequestData, error } = await supabase
                    .from("campaign_request")
                    .update({ status: "ACCEPTED" })
                    .eq("user_id", user.id)
                    .eq("campaign_id", campaign_id)
                    .select("campaign(creator_id)")
                    .single();
                if (error) {
                    return { error: error?.message };
                }
                const { data: chatData, error: chatError } = await supabase
                    .from("chat")
                    .insert({ campaign_id: campaign_id })
                    .select("id")
                    .single();
                if (chatError) {
                    return { error: chatError?.message };
                }
                const campaignCreatorId = (campaignRequestData?.campaign as { creator_id: string })?.creator_id;
                const { error: chatUserError } = await supabase.from("user_chat").insert([
                    { user_id: user.id, chat_id: chatData?.id },
                    { user_id: campaignCreatorId, chat_id: chatData?.id },
                ]);
                if (chatUserError) {
                    return { error: chatUserError?.message };
                }

                return { data: null };
            },
            invalidatesTags: ["InfluencerCampaigns"],
        }),
        getCampaignInvites: builder.query<Offer[], void>({
            queryFn: async () => {
                const { user, error: userError } = await getUser();
                if (userError) {
                    return { error: userError };
                }
                const campaignInvitationsQuery = `
                    id,
                    user_id,
                    campaign_id,
                    campaign(
                        campaign_name
                    ),
                    status,
                    description,
                    proposed_pay,
                    additional_info,
                    contract_type
                    `;
                const { data, error: campaignError } = await supabase
                    .from("campaign_request")
                    .select(campaignInvitationsQuery)
                    .eq("user_id", user.id)
                    .eq("status", "PENDING");
                if (campaignError) {
                    return { error: campaignError };
                }
                return { data: data as Offer[] };
            },
            providesTags: ["InfluencerCampaigns"],
        }),
        getCampaignById: builder.query<Campaign, string>({
            queryFn: async (id) => {
                const { error: userError } = await getUser();
                if (userError) {
                    return { error: userError };
                }
                const campaignQuery = `
				id,
				creator_id,
				campaign_name,
				campaign_description,
				ad_type,
				campaign_target,
				requirements,
				budget,
				target_audience(*),
				product_type,
				content_nieche
				`;
                const { data, error: campaignError } = await supabase
                    .from("campaign")
                    .select(campaignQuery)
                    .eq("id", id)
                    .single();
                if (campaignError) {
                    return { error: campaignError };
                }
                return { data };
            },
        }),
        getInfluencerCampaigns: builder.query<Offer[], void>({
            queryFn: async () => {
                const { user, error: userError } = await getUser();
                if (userError) {
                    return { error: userError };
                }
                const campaignInvitationsQuery = `
                    id,
                    user_id,
                    campaign_id,
                    campaign(
                        chat(id, user_chat!inner(id)),
                        campaign_name
                    ),
                    status,
                    description,
                    proposed_pay,
                    additional_info,
                    contract_type
                    `;
                const { data, error: campaignError } = await supabase
                    .from("campaign_request")
                    .select(campaignInvitationsQuery)
                    .eq("user_id", user.id)
                    .eq("status", "ACCEPTED")
                    .eq("campaign.chat.user_chat.user_id", user.id);
                if (campaignError) {
                    return { error: campaignError };
                }
                return { data: data as Offer[] };
            },
            providesTags: ["InfluencerCampaigns"],
        }),
    }),
});
export const {
    useGetCampaignsQuery,
    usePostCampaignMutation,
    useLazyGetCampaignByIdQuery,
    useLazyGetCampaignInfluencersQuery,
    usePostCampaignInfluencersMutation,
    useLazyGetRecommendedInfluencersQuery,
    usePostAcceptCampaignRequestMutation,
    useGetCampaignInvitesQuery,
    useGetInfluencerCampaignsQuery,
} = campaignApi;
export { campaignApi };
