import { yupResolver } from "@hookform/resolvers/yup";
import { Button, PasswordInput } from "@mantine/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";

interface ResetPasswordData {
	password: string;
	repeatPassword: string;
}
const ResetPasswordSchema = Yup.object().shape({
	password: Yup.string().min(6, "Too Short!").max(33, "Too Long!").required("Required"),
	repeatPassword: Yup.string()
		.min(6, "Too Short!")
		.max(33, "Too Long!")
		.required("Required")
		.oneOf([Yup.ref("password"), null], "Passwords don't match!"),
});
const ResetPassword = () => {
	const { confirmationCode } = useParams();
	const { handleSubmit, formState, control } = useForm<ResetPasswordData>({
		resolver: yupResolver(ResetPasswordSchema),
		defaultValues: {
			password: "",
			repeatPassword: "false",
		},
	});
	const onSubmit = (data: ResetPasswordData) => {
		// send to backend with code
	};
	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="w-full h-[28rem] max-w-md flex flex-col rounded-xl min-w-sm justify-center shadow-stone-300 py-5 px-16 bg-white text-slate-800"
		>
			<h1 className="text-3xl font-semibold text-center mt-4 mb-6">Enter new Password</h1>
			<div className="w-full relative mb-7">
				<Controller
					name="password"
					control={control}
					render={({ field }) => <PasswordInput label="Password" placeholder="••••••••" error={formState.errors["password"]?.message} {...field} />}
				/>
			</div>
			<div className="w-full relative mb-7">
				<Controller
					name="repeatPassword"
					control={control}
					render={({ field }) => (
						<PasswordInput label="Confirm Password" placeholder="••••••••" error={formState.errors["repeatPassword"]?.message} {...field} />
					)}
				/>
			</div>

			<Button radius="md" className="mb-10">
				Reset Password
			</Button>
			<p className="text-sm">
				<Link to="/login" replace>
					Go back
				</Link>
			</p>
		</form>
	);
};

export default ResetPassword;
