import React, { useMemo, useRef } from "react";
import * as Yup from "yup";
import PageWrapper from "./PageWrapper";
import GenderDistribution from "../../../types/GenderDistribution";
import { NumberInput, Select } from "@mantine/core";
import { selectInfluencerTargetAudience, setTargetAudience } from "../../../store/reducers/AccountInformation/AccountInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import { useGetCountriesQuery } from "../../../store/api/countries/countriesApi";
import { TargetAudience } from "../../../shared/types";

type Props = {
	handleNext: () => void;
	handlePrev: () => void;
};

const TargetAudienceSchema = Yup.object().shape({
	minAge: Yup.number().min(2, "Too low!").max(100, "Too High!").required("Required"),
	maxAge: Yup.number().min(Yup.ref("minAge"), "Maximum Age Cant be lower!").max(100, "Too High!").required("Required"),
	destinationCountry: Yup.number().required("Required"),
	gender_distribution: Yup.string().required("Required"),
});

const TargetAudienceInfo = ({ handleNext, handlePrev }: Props) => {
	const dispatch = useAppDispatch();
	const values = useAppSelector(selectInfluencerTargetAudience);
	const countries = useGetCountriesQuery();
	const countriesValues = useMemo(
		() => countries.data?.map((country) => ({ label: country.name || "Unnamed", value: country.id.toString() })) || [],
		[countries]
	);
	const { handleSubmit, formState, control } = useForm<TargetAudience>({
		resolver: yupResolver(TargetAudienceSchema),
		defaultValues: values,
	});
	const submit = useRef<HTMLFormElement>(null);
	const onSubmit = (data: TargetAudience) => {
		// redux add data
		dispatch(setTargetAudience(data));
		handleNext();
	};
	const requestSubmit = () => {
		submit.current?.requestSubmit();
	};

	return (
		<PageWrapper handlePrev={handlePrev} handleSubmit={requestSubmit}>
			<h1 className="text-xl text-slate-800 font-bold text-center">Enter information about your audience</h1>
			<form ref={submit} onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5 h-full mt-5 justify-center px-4">
				<div className="w-full relative mb-6 flex flex-col">
					<Controller
						name="destinationCountry"
						control={control}
						render={({ field }) => (
							<Select
								label="Audience Country"
								error={formState.errors["destinationCountry"]?.message}
								{...field}
								searchable
								data={[...countriesValues]}
							></Select>
						)}
					/>
				</div>
				<div className="w-full relative mb-6 flex flex-col">
					<Controller
						name="gender_distribution"
						control={control}
						render={({ field }) => (
							<Select
								label="Gender Distribution"
								error={formState.errors["gender_distribution"]?.message}
								{...field}
								data={[
									...Object.keys(GenderDistribution).map((val) => {
										return { value: val, label: Object(GenderDistribution)[val] };
									}),
								]}
							></Select>
						)}
					/>
				</div>
				<div className="flex flex-row gap-5 h-full mt-5">
					<div className="w-full relative mb-6">
						<Controller
							name="minAge"
							control={control}
							render={({ field }) => <NumberInput label="Minimum Age" error={formState.errors["minAge"]?.message} {...field} />}
						/>
					</div>
					<div className="w-full relative mb-6">
						<Controller
							name="maxAge"
							control={control}
							render={({ field }) => <NumberInput label="Maximum Age" error={formState.errors["maxAge"]?.message} {...field} />}
						/>
					</div>
				</div>
			</form>
		</PageWrapper>
	);
};

export default TargetAudienceInfo;
