import React from "react";
import Login from "../../components/authorization/Login";

const LoginPage = () => {
	return (
		<div className="w-full h-full overflow-hidden flex justify-center pt-10">
			<Login />
		</div>
	);
};

export default LoginPage;
