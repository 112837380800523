import React, { useEffect } from "react";
import Loading from "../../components/loading/Loading";
import { useGetUserQuery } from "../../store/api/auth/authApi.api";
import { useAppDispatch } from "../../store/hooks";
import { setAuthState } from "../../store/reducers/Auth/AuthSlice";
import { supabase } from "../../supabase";

type Props = {
    children: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
    const dispatch = useAppDispatch();
    const { isLoading: userLoading } = useGetUserQuery();

    useEffect(() => {
        const unsubsribe = supabase.auth.onAuthStateChange((e, session) => {
            dispatch(setAuthState({ session, user: session?.user }));
        });

        return () => {
            unsubsribe.data.subscription.unsubscribe();
        };
        // eslint-disable-next-line
    }, []);
    if (userLoading) return <Loading />;
    return <>{children}</>;
};

export default AuthProvider;
