import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FileInput, Textarea } from "@mantine/core";
import { IconSend, IconUpload } from "@tabler/icons";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useSendMessageMutation } from "../../../store/api/chat/chat.api";

import Messages from "./Messages";

type Props = {
    chatId: string;
};

interface Message {
    message: string;
    attachment: File;
}

const MessageSchema = Yup.object().shape({
    message: Yup.string()
        .max(300, "Too long, a message shouldn't be longer than 300 letters.")
        .required("Message cannot be empty"),
    attachment: Yup.mixed().test("fileSize", "The file is too large. Max file size allowed is 2.5mb", (value) => {
        if (value) {
            return value.size <= 2500000;
        } else {
            return true;
        }
    }),
});

const Chat = ({ chatId }: Props) => {
    const [sendMessage] = useSendMessageMutation();
    const { handleSubmit, formState, control, reset } = useForm<Message>({
        resolver: yupResolver(MessageSchema),
    });

    const onSubmit = (data: Message) => {
        reset({ message: "", attachment: undefined });
        sendMessage({ message: data.message, chat_id: chatId, attachment: data.attachment });
    };

    return (
        <div className="w-3/4 h-full flex flex-col justify-end">
            <Messages id={chatId} />
            <form className="flex flex-row p-5 w-full h-2/12" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                        <Textarea
                            placeholder="Your message..."
                            variant="filled"
                            radius="lg"
                            size="md"
                            className="w-8/12"
                            maxRows={2}
                            autosize
                            error={formState.errors["message"]?.message}
                            {...field}
                        />
                    )}
                />
                <Controller
                    name="attachment"
                    control={control}
                    render={({ field }) => (
                        <FileInput
                            placeholder="Attach documents"
                            radius="md"
                            size="md"
                            className="my-auto w-3/12 mx-3"
                            icon={<IconUpload size={20} />}
                            clearable
                            accept="application/.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                            error={formState.errors["attachment"]?.message}
                            {...field}
                        />
                    )}
                />

                <Button type="submit" variant="light" radius="lg" className="my-auto w-1/12" size="md">
                    <IconSend />
                </Button>
            </form>
        </div>
    );
};

export default Chat;
