import { Pagination, Tabs } from "@mantine/core";
import React, { useEffect, useState } from "react";
import Loading from "react-loading";
import { useNavigate, useParams } from "react-router";

import Layout from "../../layouts/Layout";
import { useLazyGetCampaignByIdQuery } from "../../store/api/campaign/campaign.api";
import { AddInfluencersPanel } from "./components/AddInfluencers";
import { HiredInfluencers } from "./components/HiredInfluencers";
import SearchBar from "./components/SearchBar";

const ManageInfluencers = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);

    const { id } = useParams();

    const [fetchCampaign, { data: campaign, isLoading }] = useLazyGetCampaignByIdQuery();
    useEffect(() => {
        if (id) {
            fetchCampaign(id);
        } else {
            navigate("/not_found");
        }
    }, [id]);

    if (!campaign || isLoading) return <Loading />;

    return (
        <Layout lg>
            <div className="flex flex-col p-3">
                <Tabs defaultValue="current">
                    <Tabs.List>
                        <Tabs.Tab value="current">
                            <h1 className="text-2xl font-bold">Hired Influencers</h1>
                        </Tabs.Tab>
                        <Tabs.Tab value="add" pl="10px">
                            <h1 className="text-2xl font-bold">Add Influencers</h1>
                        </Tabs.Tab>
                    </Tabs.List>
                    <SearchBar />
                    <Tabs.Panel value="current">
                        <HiredInfluencers></HiredInfluencers>
                    </Tabs.Panel>

                    <Tabs.Panel value="add">
                        <AddInfluencersPanel campaign={campaign}></AddInfluencersPanel>
                    </Tabs.Panel>
                </Tabs>
                <Pagination total={20} page={page} onChange={setPage} mt={10} size={"lg"} />
            </div>
        </Layout>
    );
};

export default ManageInfluencers;
