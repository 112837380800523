import React, { useState } from "react";
import NewsletterWindow from "./NewsletterWindow";
import { Button, Popover } from "@mantine/core";

const Newsletter = () => {
    const [open, setOpen] = useState(false);
    return (
        <div className="fixed right-0 bottom-0 text-black-800 font-semibold m-5 z-10">
            <Popover width={330} position="top-end" withArrow shadow="md" opened={open} onChange={setOpen}>
                <Popover.Target>
                    <Button onClick={() => setOpen((o) => !o)} className="mr-5" size="md">
                        Interested?
                    </Button>
                </Popover.Target>
                <Popover.Dropdown className="mr-5">
                    <NewsletterWindow handleClose={() => setOpen(false)} />
                </Popover.Dropdown>
            </Popover>
        </div>
    );
};

export default Newsletter;
