import { supabase } from "../supabase/index";

export const getUser = async () => {
	const {
		data: { user },
	} = await supabase.auth.getUser();
	if (!user) {
		return {
			error: {
				message: "You are not logged in.",
			},
		};
	}

	return { user };
};
export const getSession = async () => {
	const {
		data: { session },
	} = await supabase.auth.getSession();
	if (!session) {
		return {
			error: {
				message: "You are not logged in.",
			},
		};
	}

	return { session };
};
