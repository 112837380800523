import React from "react";
import { Navigate, Outlet } from "react-router";
import { useGetUserQuery } from "../store/api/auth/authApi.api";

const CompanyRoute = () => {
	const { data } = useGetUserQuery();
	return data?.account_type === "COMPANY" ? <Outlet /> : <Navigate replace to={"/not_found"} />;
};

export default CompanyRoute;
