import { MantineProvider, createEmotionCache } from "@mantine/core";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store/store";

import "./index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./providers/AuthProvider/AuthProvider";
import RedirectProvider from "./providers/RedirectProvider/RedirectProvider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const myCache = createEmotionCache({
	key: "mantine",
	prepend: false,
});
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<MantineProvider
				emotionCache={myCache}
				theme={{
					fontFamily: "Poppins, sans-serif",
					fontFamilyMonospace: "Monaco, Courier, monospace",
					headings: { fontFamily: "Greycliff CF, sans-serif" },
				}}
			>
				<Provider store={store}>
					<AuthProvider>
						<RedirectProvider>
							<App />
						</RedirectProvider>
					</AuthProvider>
				</Provider>
			</MantineProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
