import { Button, Divider, Group, Modal, Stepper } from "@mantine/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Offer from "../../../types/Offer";
import OfferForm from "./OfferForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ContractType from "./ContractType";
import Campaign, { Influencer } from "../../../store/api/campaign/campaign.types";
import { usePostCampaignInfluencersMutation } from "../../../store/api/campaign/campaign.api";
import { toast } from "react-toastify";
type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    user: Influencer;
    campaign: Campaign;
};
const validationSchema = yup.object({
    description: yup.string().required("Required"),
    additional_info: yup.string().required("Required"),
    proposed_pay: yup.number().min(1, "Minimum offer is 1 PLN").required("Required"),
    contract_type: yup.string().required("Required"),
});
const MakeOfferModal = ({ open, setOpen, user, campaign }: Props) => {
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const submit = useRef<HTMLFormElement>(null);
    const [inviteUser, { isLoading }] = usePostCampaignInfluencersMutation();
    const { handleSubmit, formState, control } = useForm<Offer>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            description: "",
            additional_info: "",
            proposed_pay: 1,
            contract_type: "CONTRACT_OF_EMPLOYMENT",
        },
    });
    const onSubmit = (data: Offer) => {
        data = { ...data, user_id: user.id, campaign_id: campaign.id || "1", status: "PENDING" };
        inviteUser(data)
            .then(() => {
                setOpen(false);
            })
            .catch((err) => toast.error(err.message));
    };
    const errors = useMemo(() => formState.errors, [{ ...formState.errors }]);
    useEffect(() => {
        if (formState.errors.additional_info || formState.errors.description || formState.errors.proposed_pay) {
            setActive(0);
        } else if (formState.errors.contract_type) {
            setActive(1);
        }
    }, [errors]);
    if (!user.influencer_info) {
        return <></>;
    }
    return (
        <Modal
            closeOnEscape={false}
            closeOnClickOutside={false}
            size={"xl"}
            opened={open}
            onClose={() => setOpen(false)}
            title={<p className="text-xl font-semibold">{campaign.campaign_name}</p>}
        >
            <div className="flex flex-col items-center justify-center">
                <p className="text-xl font-medium">{user.username}</p>
                <p>
                    {user.influencer_info.name} {user.influencer_info.last_name}
                </p>
            </div>
            <Divider />
            <form ref={submit} onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 gap-5 mt-5">
                <Stepper active={active} onStepClick={setActive} breakpoint="sm">
                    <Stepper.Step label="Offer information" description="Fill out offer information">
                        <OfferForm control={control} formState={formState} />
                    </Stepper.Step>
                    <Stepper.Step label="Contract type" description="Desired Contract Type">
                        <ContractType control={control} />
                    </Stepper.Step>

                    <Stepper.Completed>
                        <div className="flex justify-center items-center">
                            <Button type="submit" loading={isLoading}>
                                Make an Offer
                            </Button>
                        </div>
                    </Stepper.Completed>
                </Stepper>
            </form>
            <Group position="center" mt="xl">
                <Button variant="default" onClick={prevStep}>
                    Back
                </Button>

                <Button onClick={nextStep} hidden>
                    Next step
                </Button>
            </Group>
        </Modal>
    );
};

export default MakeOfferModal;
