import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "../../../shared/types";
import { authApi } from "../../api/auth/authApi.api";

const initialState: AuthState = {
	user: null,
	session: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setAuthState: (state, action) => {
			return { user: action.payload.user, session: action.payload.session };
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, { payload }) => {
			state.session = payload.session;
			state.user = payload.user;
		});
		builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
			state.session = null;
			state.user = null;
		});
	},
});

export default authSlice.reducer;
export const { setAuthState } = authSlice.actions;
