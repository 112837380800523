import { CompanyInformationData } from "./AccountInformation.types";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { AccountType } from "../../../types/enums.types";
import { InfluencerInformationData } from "../../../types/InfluencerInformation.types";

interface InformationState {
    accountType: AccountType | null;
    influencerInformation: InfluencerInformationData;
    companyInformation: CompanyInformationData;
}

const initialState: InformationState = {
    accountType: null,
    influencerInformation: {
        id: 0,
        name: "",
        last_name: "",
        content_nieche: [],
        target_audience: {
            minAge: 0,
            maxAge: 0,
            destinationCountry: "0",
            gender_distribution: "MAINLY_MAN",
        },
        influencer_profiles: [],
    },
    companyInformation: {
        company_name: "",
        nip: "",
        legal_entity_type: "JEDNOOSOBOWA_DZIALANOSC_GOSPODARCZA",
        registration_date: new Date().toISOString(),
    },
};
const accountInformationSlice = createSlice({
    name: "accountInformation",
    initialState,
    reducers: {
        setAccountType: (state, action) => {
            return { ...state, accountType: action.payload };
        },
        setCompanyInformation: (state, action) => {
            return { ...state, companyInformation: action.payload };
        },
        setInfluencerInformation: (state, action) => {
            return { ...state, influencerInformation: action.payload };
        },
        setTargetAudience: (state, action) => {
            return {
                ...state,
                influencerInformation: {
                    ...state.influencerInformation,
                    target_audience: action.payload,
                },
            };
        },
    },
});

export default accountInformationSlice.reducer;
export const { setCompanyInformation, setInfluencerInformation, setTargetAudience, setAccountType } =
    accountInformationSlice.actions;
export const selectInfluencerInformation = (state: RootState) => state.accountInformation.influencerInformation;
export const selectInfluencerTargetAudience = (state: RootState) =>
    state.accountInformation.influencerInformation.target_audience;
export const selectCompanyInformation = (state: RootState) => state.accountInformation.companyInformation;
export const selectInformation = (state: RootState) =>
    state.accountInformation.accountType === "COMPANY"
        ? state.accountInformation.companyInformation
        : state.accountInformation.influencerInformation;
export const selectAccountType = (state: RootState) => state.accountInformation.accountType;
