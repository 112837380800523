import { Stepper } from "@mantine/core";
import React, { useState } from "react";

import CompanyInfo from "./CompanyInfo";
import InformationFilled from "./InformationFilled";

type Props = {
	handleChangeType: () => void;
};
const CompanyStepper = ({ handleChangeType }: Props) => {
	const [active, setActive] = useState(0);
	const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
	const prevStep = () => setActive((current) => (current > -1 ? current - 1 : current));
	if (active === -1) {
		handleChangeType();
	}
	return (
		<>
			<Stepper active={active}>
				<Stepper.Step label="First Step" description="Information about your company">
					<CompanyInfo handleNext={nextStep} handlePrev={prevStep} />
				</Stepper.Step>
				<Stepper.Completed>
					<InformationFilled handlePrev={prevStep} />
				</Stepper.Completed>
			</Stepper>
		</>
	);
};

export default CompanyStepper;
