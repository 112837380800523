import { yupResolver } from "@hookform/resolvers/yup";
import { Accordion, Button, Checkbox, Group, Modal, ScrollArea, Center } from "@mantine/core";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IconMoodSad } from "@tabler/icons";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ContractType from "../../../data/ContractType";

import {
    useGetCampaignInvitesQuery,
    usePostAcceptCampaignRequestMutation,
} from "../../../store/api/campaign/campaign.api";

interface isAcceptedChecker {
    isAccepted: boolean;
}

const ContractAcceptSchema = Yup.object().shape({
    isAccepted: Yup.boolean().required().oneOf([true], "Tick this to accept the contract above and continue."),
});

const PendingPartnerships = () => {
    const { handleSubmit, control } = useForm<isAcceptedChecker>({
        resolver: yupResolver(ContractAcceptSchema),
    });
    const [selectedCampaignId, setSelectedCampaignId] = useState("");
    const [opened, setOpened] = useState(false);
    const [acceptCampaign] = usePostAcceptCampaignRequestMutation();
    const { data } = useGetCampaignInvitesQuery();
    const onSubmit = () => {
        if (selectedCampaignId === "") {
            toast.error("Please select a campaign");
            return;
        }
        acceptCampaign({ campaign_id: selectedCampaignId });
    };
    if (data?.length === 0) {
        return (
            <div className="flex flex-col items-center mt-4">
                <IconMoodSad width={64} height={64} stroke={0.7} color="#b5b8bf" />
                <p className="text-gray-500 text-opacity-50 text-center">You don't have any pending partnerships.</p>
            </div>
        );
    }

    return (
        <div>
            <Modal opened={opened} onClose={() => setOpened(false)} title="Contract Preview">
                <div className="border p-4">
                    <ScrollArea style={{ height: 250 }} offsetScrollbars scrollbarSize={4}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel
                        sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius
                        sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo
                        autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
                        nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum
                        nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa
                        laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
                        recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem!
                        Officiis iure rerum voluptates a cumque velit quibusdam sed amet tempora. Sit laborum ab, eius
                        fugit doloribus tenetur fugiat, temporibus enim commodi iusto libero magni deleniti quod quam
                        consequuntur! Commodi minima excepturi repudiandae velit hic maxime doloremque. Quaerat
                        provident commodi consectetur veniam similique ad earum omnis ipsum saepe, voluptas, hic
                        voluptates pariatur est explicabo fugiat, dolorum eligendi quam cupiditate excepturi mollitia
                        maiores labore suscipit quas? Nulla, placeat. Voluptatem quaerat non architecto ab laudantium
                        modi minima sunt esse temporibus sint culpa, recusandae aliquam numquam totam ratione voluptas
                        quod exercitationem fuga. Possimus quis earum veniam quasi aliquam eligendi, placeat qui
                        corporis!
                    </ScrollArea>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Group position="left" className="pt-5">
                        <Controller
                            name="isAccepted"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref }, fieldState: {}, formState }) => (
                                <Checkbox
                                    label="I agree to sign the contract"
                                    onBlur={onBlur}
                                    error={formState.errors["isAccepted"]?.message}
                                    onChange={onChange}
                                    checked={value}
                                    ref={ref}
                                />
                            )}
                        />
                    </Group>
                    <Group position="apart" className="mt-5">
                        <Button variant="light" onClick={() => setOpened(false)}>
                            Cancel
                        </Button>
                        <Button type="submit"> Accept</Button>
                    </Group>
                </form>
            </Modal>
            <div className="flex mx-3">
                <Accordion variant="separated" radius="md" defaultValue="customization" className="w-full">
                    {data?.map((item) => (
                        <Accordion.Item value={item.campaign_id.toString()} className="mt-4">
                            <Accordion.Control>
                                <p className="text-xl">{item.campaign.campaign_name}</p>
                                <p className="text-opacity-70 text-black">{item.description}</p>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <div className="w-full">
                                    <p className="text-opacity-60 text-gray-500">
                                        <span className="text-black">Proposed Pay: </span> {item.proposed_pay}$
                                    </p>
                                    <p className="text-opacity-60 text-gray-500">
                                        <span className="text-black">Contract type: </span>
                                        {ContractType[item.contract_type]}
                                    </p>
                                    <p className="text-opacity-60 text-gray-500">
                                        <span className="text-black">Additional Information: </span>
                                        <p className="max-w-full">{item.additional_info} </p>
                                    </p>
                                    <div>
                                        <Button
                                            className="mt-2 mr-2"
                                            onClick={() => {
                                                setSelectedCampaignId(item.campaign_id);
                                                setOpened(true);
                                            }}
                                        >
                                            Preview Contract
                                        </Button>
                                    </div>
                                </div>
                            </Accordion.Panel>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
    );
};

export default PendingPartnerships;
