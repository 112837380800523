import React from "react";
import Register from "../../components/authorization/Register";

const RegisterPage = () => {
	return (
		<div className="w-full h-full overflow-hidden flex justify-center pt-10">
			<Register />
		</div>
	);
};

export default RegisterPage;
