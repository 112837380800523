import React from "react";
import { Navigate, Route, Routes } from "react-router";
import Navbar from "../components/navbar/Navbar";
import LoginPage from "./Auth/LoginPage";
import ForgotPasswordPage from "./Auth/ForgotPasswordPage";
import RegisterPage from "./Auth/RegisterPage";
import CompleteInformationPage from "./CompleteInformation/CompleteInformationPage";
import LandingPage from "./LandingPage/LandingPage";
import PasswordResetPage from "./Auth/PasswordResetPage";
import DashboardCompany from "./dashboard/DashboardCompany";
import DashboardInfluencer from "./dashboard/DashboardInfluencer";
import CampaignPage from "./Campaign/CampaignPage";
import AddCampaign from "./Campaign/AddCampaign";
import EditCampaign from "./Campaign/EditCampaign";
import ManageInfluencers from "./Campaign/ManageInfluencers";
import InfluencerAccountPage from "./Accounts/InfluencerAccountPage";
import CompanyAccountPage from "./Accounts/CompanyAccountPage";
import InfluencerEdit from "./Accounts/components/InfluencerEdit";
import ChatPage from "./Chat/ChatPage";
import AuthenticatedRoute from "../routes/AuthenticatedRoute";
import { NotFoundPage } from "./NotFound/NotFoundPage";
import InfluencerRoute from "../routes/InfluencerRoute";
import CompanyRoute from "../routes/CompanyRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    // TODO: Slugify the campaign name and influencer
    return (
        <>
            <Navbar />
            <Routes>
                {/* Public */}
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/password_reset" element={<ForgotPasswordPage />} />
                <Route path="/not_found" element={<NotFoundPage />} />
                <Route path="/password_reset/:confirmationCode" element={<PasswordResetPage />} />
                <Route path="/company/:id" element={<CompanyAccountPage />} />
                <Route path="/influencer/:id" element={<InfluencerAccountPage />} />

                {/* Company Routes  */}
                <Route path="/" element={<CompanyRoute />}>
                    <Route path="/campaign/add" element={<AddCampaign />} />
                    <Route path="/campaign/:id/edit" element={<EditCampaign />} />
                    <Route path="/campaign/:id/influencers" element={<ManageInfluencers />} />
                    <Route path="/campaign/:id" element={<CampaignPage />} />
                    <Route path="/dashboard/company" element={<DashboardCompany />} />
                </Route>

                {/* Influencer routes  */}
                <Route path="/" element={<InfluencerRoute />}>
                    <Route path="/influencer/:id/edit" element={<InfluencerEdit />} />
                    <Route path="/dashboard/influencer" element={<DashboardInfluencer />} />
                </Route>

                {/* Authenticated  */}
                <Route path="/" element={<AuthenticatedRoute />}>
                    <Route path={"/chat/:id"} element={<ChatPage />} />
                    <Route path="/complete_information/*" element={<CompleteInformationPage />} />
                </Route>
                <Route path="*" element={<Navigate to={"/not_found"} replace />} />
            </Routes>
            <ToastContainer position={"bottom-center"} pauseOnHover={false} />
        </>
    );
}
export default App;
