import { Box, Burger, Button, Divider, Drawer, Group, Header, ScrollArea, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Logo from "../../images/Logotyp Black.png";
import { useGetUserQuery, useLogoutMutation } from "../../store/api/auth/authApi.api";
import { useAppSelector } from "../../store/hooks";
import { selectIsLoggedIn } from "../../store/reducers/Auth/Selectors";

const useStyles = createStyles((theme) => ({
    link: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: "none",
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,

        [theme.fn.smallerThan("sm")]: {
            height: 42,
            display: "flex",
            alignItems: "center",
            width: "100%",
        },

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        }),
    },

    subLink: {
        width: "100%",
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
        borderRadius: theme.radius.md,

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
        }),

        "&:active": theme.activeStyles,
    },

    dropdownFooter: {
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
        margin: -theme.spacing.md,
        marginTop: theme.spacing.sm,
        padding: `${theme.spacing.md}px ${theme.spacing.md * 2}px`,
        paddingBottom: theme.spacing.xl,
        borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]}`,
    },

    hiddenMobile: {
        [theme.fn.smallerThan("sm")]: {
            display: "none",
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan("sm")]: {
            display: "none",
        },
    },

    box: {
        width: "90%",
        borderRadius: "0 0 20px 20px",
        border: "1px solid #e4ecec",
        borderTop: "0",
        [theme.fn.smallerThan("sm")]: {
            width: "100%",
            borderRadius: "0",
            border: "0",
            borderBottom: "1px solid #e4ecec",
        },
    },
}));

export default function Navbar() {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const { classes, theme } = useStyles();
    const loggedIn = useAppSelector(selectIsLoggedIn);
    const [logout] = useLogoutMutation();
    const { data: user } = useGetUserQuery();
    const navigate = useNavigate();
    return (
        <Box className="flex items-center justify-center">
            <Header height={60} px="md" className={classes.box}>
                <Group position="apart" sx={{ height: "100%" }}>
                    {/* LOGO */}
                    <div
                        className="h-full flex justify-center items-center cursor-pointer"
                        onClick={() => navigate("/")}
                    >
                        <img alt={"Logo"} src={Logo} className="object-cover h-1/2" />
                    </div>
                    {loggedIn ? (
                        <Group position="center" className={classes.hiddenMobile}>
                            <Button
                                onClick={() => navigate("/dashboard/" + user?.account_type?.toLocaleLowerCase())}
                                variant="subtle"
                                color="dark"
                            >
                                Dashboard
                            </Button>
                            {user?.account_type === "INFLUENCER" && (
                                <Button onClick={() => navigate("/influencer/" + user?.id)} variant="default">
                                    Your Profile
                                </Button>
                            )}

                            <Button onClick={() => logout()}>Logout</Button>
                        </Group>
                    ) : (
                        <Group position="center" className={classes.hiddenMobile}>
                            <Button onClick={() => navigate("/login")} variant="default">
                                Log in
                            </Button>
                            <Button onClick={() => navigate("/register")}>Sign up</Button>
                        </Group>
                    )}

                    <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
                </Group>
            </Header>

            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                title={<img src={Logo} className="w-1/2" />}
                className={classes.hiddenDesktop}
                zIndex={1000000}
            >
                <ScrollArea sx={{ height: "calc(100vh - 60px)" }} mx="-md">
                    <Divider my="sm" color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"} />
                    {/* check user type */}
                    {loggedIn ? (
                        <div>
                            <Link to="/" className={classes.link} onClick={() => closeDrawer()}>
                                Home
                            </Link>
                            <Link
                                to={"/dashboard/" + user?.account_type?.toLowerCase()}
                                className={classes.link}
                                onClick={() => closeDrawer()}
                            >
                                Dashboard
                            </Link>
                            {user?.account_type === "INFLUENCER" && (
                                <Link
                                    to={"/influencer/" + user?.id}
                                    className={classes.link}
                                    onClick={() => closeDrawer()}
                                >
                                    Your Profile
                                </Link>
                            )}
                            <Divider my="sm" color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"} />
                        </div>
                    ) : (
                        <></>
                    )}

                    {loggedIn ? (
                        <Group position="center" grow pb="xl" px="md">
                            {user?.account_type === "INFLUENCER" && <Button onClick={() => logout()}>Logout</Button>}
                        </Group>
                    ) : (
                        <Group position="center" grow pb="xl" px="md">
                            <Button
                                onClick={() => {
                                    navigate("/login"), closeDrawer();
                                }}
                                variant="default"
                            >
                                Log in
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate("/register"), closeDrawer();
                                }}
                            >
                                Sign up
                            </Button>
                        </Group>
                    )}
                </ScrollArea>
            </Drawer>
        </Box>
    );
}
