import React, { useRef } from "react";
import PageWrapper from "./PageWrapper";
import * as Yup from "yup";
import ContentNieche from "../../../data/ContentNieche";
import { MultiSelect, TextInput } from "@mantine/core";
import {
    selectInfluencerInformation,
    setInfluencerInformation,
} from "../../../store/reducers/AccountInformation/AccountInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InfluencerInformationData } from "../../../types/InfluencerInformation.types";

type Props = {
    handleNext: () => void;
    handlePrev: () => void;
};

const InfluencerSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").max(33, "Too Long!").required("Required"),
    last_name: Yup.string().min(2, "Too Short!").max(33, "Too Long!").required("Required"),
    content_nieche: Yup.array().min(1, "Pick at least one").required("Required"),
});
const InfluencerInfo = ({ handleNext, handlePrev }: Props) => {
    const dispatch = useAppDispatch();
    const values = useAppSelector(selectInfluencerInformation);
    const { handleSubmit, formState, control } = useForm<InfluencerInformationData>({
        resolver: yupResolver(InfluencerSchema),
        defaultValues: values,
    });
    const submit = useRef<HTMLFormElement>(null);
    const onSubmit = (data: InfluencerInformationData) => {
        dispatch(setInfluencerInformation(data));
        handleNext();
    };
    const requestSubmit = () => {
        submit.current?.requestSubmit();
    };
    return (
        <PageWrapper handleSubmit={requestSubmit} handlePrev={handlePrev}>
            <h1 className="text-xl text-slate-800 font-bold text-center">Fill out your profile informations</h1>
            <form
                ref={submit}
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-5 h-full mt-5 justify-center px-4"
            >
                <div className="flex flex-row gap-5 h-full mt-5">
                    <div className="w-full relative mb-6">
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <TextInput
                                    withAsterisk
                                    label="Name"
                                    error={formState.errors["name"]?.message}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className="w-full relative mb-6">
                        <Controller
                            name="last_name"
                            control={control}
                            render={({ field }) => (
                                <TextInput
                                    withAsterisk
                                    label="Last Name"
                                    error={formState.errors["last_name"]?.message}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="w-full mb-6 flex flex-col max-w-md">
                    <Controller
                        name="content_nieche"
                        control={control}
                        render={({ field }) => (
                            <MultiSelect
                                error={formState.errors["content_nieche"]?.message}
                                {...field}
                                label="Content Nieche"
                                data={[
                                    ...Object.values(ContentNieche).map((value) => {
                                        return { value: value, label: value };
                                    }),
                                ]}
                            ></MultiSelect>
                        )}
                    />
                </div>
            </form>
        </PageWrapper>
    );
};

export default InfluencerInfo;
