import { Group, Button } from "@mantine/core";
import React from "react";

type Props = {
	handlePrev: () => void;
	handleSubmit?: () => void;
	children: React.ReactNode;
};

const PageWrapper = ({ handlePrev, handleSubmit, children }: Props) => {
	return (
		<div className="flex flex-col mt-5">
			<div>{children}</div>
			<Group position="center" mt="xl">
				<Button variant="default" onClick={handlePrev}>
					Back
				</Button>
				<Button onClick={handleSubmit}>Next step</Button>
			</Group>
		</div>
	);
};

export default PageWrapper;
