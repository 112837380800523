import { Button, Image } from "@mantine/core";
import { IconUserCircle } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useFilePicker } from "use-file-picker";
import { useUpdateAvatarMutation } from "../../../store/api/completeInformation/accountInformation.api";

type Props = {
    avatarUrl?: string;
    editable?: boolean;
};

const InfluencerAvatar = ({ avatarUrl, editable }: Props) => {
    const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(avatarUrl || null);
    const [openFileSelector, { plainFiles, clear }] = useFilePicker({
        accept: "image/*",
        maxFileSize: 10,
    });
    const [updateAvatar, { isLoading }] = useUpdateAvatarMutation();
    useEffect(() => {
        if (plainFiles && plainFiles.length > 0) {
            setUploadedFileUrl(URL.createObjectURL(plainFiles[0]));
        }
    }, [plainFiles]);

    const handleSubmit = async () => {
        if (plainFiles && plainFiles.length > 0 && editable) {
            await updateAvatar({ avatarImg: plainFiles[0] });
            clear();
        }
    };
    return (
        <div className={"flex items-center flex-col justify-center"}>
            <div className="rounded-full group relative flex justify-center items-center">
                <Image
                    src={uploadedFileUrl}
                    withPlaceholder
                    placeholder={<IconUserCircle />}
                    alt="Influencer profile image"
                    radius="xl"
                    className={editable ? "group-hover:opacity-50" : ""}
                    height={260}
                    width={260}
                />
                <div className={editable ? "hidden group-hover:absolute group-hover:block mx-auto my-auto" : "hidden"}>
                    <Button onClick={openFileSelector} variant={"outline"}>
                        Pick new Avatar
                    </Button>
                </div>
            </div>
            {plainFiles.length > 0 && (
                <Button className="mt-5" onClick={handleSubmit} loading={isLoading}>
                    Save
                </Button>
            )}
        </div>
    );
};

export default InfluencerAvatar;
