import { Button } from "@mantine/core";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { usePostInformationMutation } from "../../../store/api/completeInformation/accountInformation.api";
import { useAppSelector } from "../../../store/hooks";
import { selectAccountType, selectInformation } from "../../../store/reducers/AccountInformation/AccountInformationSlice";

type Props = {
	handlePrev: () => void;
};

const InformationFilled = ({ handlePrev }: Props) => {
	const navigate = useNavigate();
	const [submitInformation, { isLoading }] = usePostInformationMutation();
	const information = useAppSelector(selectInformation);
	const accountType = useAppSelector(selectAccountType);

	const handleSubmit = () => {
		if (accountType === null) {
			return;
		}
		submitInformation({ accountType: accountType, data: information })
			.then(() => {
				navigate("/");
			})
			.catch((err) => {
				toast.error(err);
			});
	};
	return (
		<div className={"w-full mt-10"}>
			<div className="w-full rounded-lg bg-white flex flex-col gap-5 justify-center p-5 items-center">
				<h3 className="text-lg font-semibold">You're all set! Submit your information and preceed to the main page!</h3>
				<div className="w-full justify-center align-center flex gap-5">
					<Button variant="outline" size="md" radius="lg" onClick={() => handlePrev()}>
						Go Back
					</Button>
					<Button size="md" radius="lg" onClick={() => handleSubmit()} loading={isLoading}>
						Submit Information
					</Button>
				</div>
			</div>
		</div>
	);
};

export default InformationFilled;
