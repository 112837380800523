import React from "react";
import { Avatar } from "@mantine/core";

type Props = {
    text: string;
    sender: string;
    date: string;
    avatar_url?: string;
};

const Message = ({ text, sender, avatar_url, date }: Props) => {
    return (
        <div className="my-5 flex flex-row">
            <Avatar src={avatar_url} radius="xl" size="lg" />
            <div className="ml-3">
                <span className="text-black text-lg font-semibold mr-3">{sender}</span>
                <span className="text-gray-300 text-sm">{new Date(date).toLocaleString()}</span>
                <p>{text}</p>
            </div>
        </div>
    );
};

export default Message;
