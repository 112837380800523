import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import accountInformationSlice from "./reducers/AccountInformation/AccountInformationSlice";
import supabaseApi from "./api";
import AuthSlice from "./reducers/Auth/AuthSlice";

export const store = configureStore({
	reducer: {
		accountInformation: accountInformationSlice,
		authSlice: AuthSlice,
		[supabaseApi.reducerPath]: supabaseApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(supabaseApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
