import { AuthState } from "./../../../shared/types";
import { getSession } from "../../../utils/authUtils";
import { LoginParams, RegisterParams, UserProfile } from "./authApi.types";
import supabaseApi from "..";
import { supabase } from "../../../supabase";
import { setAuthState } from "../../reducers/Auth/AuthSlice";

const authApi = supabaseApi.injectEndpoints({
    endpoints: (builder) => ({
        // supabase rtk query enpoints for authentication
        signIn: builder.mutation<AuthState, LoginParams>({
            queryFn: async (params) => {
                const { data, error } = await supabase.auth.signInWithPassword(params);
                if (error) {
                    return { error: error?.message };
                }
                return { data };
            },
            invalidatesTags: ["User"],
        }),
        signUp: builder.mutation<AuthState, RegisterParams>({
            queryFn: async (params) => {
                const { data: registerData, error: registerError } = await supabase.auth.signUp({
                    ...params,
                    options: {
                        data: {
                            username: params.username,
                            email: params.email,
                        },
                    },
                });
                if (registerError) {
                    return { error: registerError?.message };
                }
                return { data: registerData };
            },
        }),
        logout: builder.mutation<null, void>({
            queryFn: async () => {
                const { error } = await supabase.auth.signOut();
                if (error) {
                    return { error };
                }
                return { data: null };
            },
            invalidatesTags: ["User"],
        }),
        getUser: builder.query<UserProfile, void>({
            queryFn: async (_, api) => {
                const { session } = await getSession();

                if (!session?.user) {
                    return { data: null };
                }
                api.dispatch(setAuthState({ user: session.user, session: session }));
                const { data, error } = await supabase.from("profiles").select("*").eq("id", session?.user.id).single();
                if (error) {
                    return { error: error.message };
                }
                return { data: data };
            },
            providesTags: ["User"],
        }),
    }),
});
export const { useSignInMutation, useLogoutMutation, useSignUpMutation, useGetUserQuery } = authApi;
export { authApi };
