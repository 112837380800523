import { Button } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router";
import CampaignItem from "../../components/compaign/CompaignItem";
import Loading from "../../components/loading/Loading";
import Layout from "../../layouts/Layout";
import { useGetCampaignsQuery } from "../../store/api/campaign/campaign.api";

const DashboardCompany = () => {
    // Fetch campaings
    const navigate = useNavigate();
    const { data: campaigns, isLoading } = useGetCampaignsQuery();

    if (isLoading) return <Loading />;
    return (
        <Layout>
            <div className="flex flex-row items-center">
                <h1 className="text-left text-2xl font-semibold">My Campaigns</h1>
                <Button className="ml-auto" onClick={() => navigate("/campaign/add")}>
                    Add Campaign
                </Button>
            </div>

            <div className="flex flex-col mt-5">
                {campaigns?.map((campaign) => (
                    <CampaignItem key={campaign.id} campaign={campaign} />
                ))}
            </div>
        </Layout>
    );
};

export default DashboardCompany;
