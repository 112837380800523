import { CountryData } from "./countriesApi.types";
import supabaseApi from "..";
import { supabase } from "../../../supabase";

const countriesApi = supabaseApi.injectEndpoints({
	endpoints: (builder) => ({
		// supabase rtk query enpoints for authentication
		getCountries: builder.query<CountryData[], void>({
			queryFn: async () => {
				const { data, error } = await supabase.from("countries").select("*");
				if (error) {
					return { error: error?.message };
				}
				return { data };
			},
		}),
	}),
});
export const { useGetCountriesQuery } = countriesApi;
export { countriesApi };
