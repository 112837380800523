import React from "react";
import { createStyles, Text, Container, Button, Group } from "@mantine/core";
import Logo from "../../../images/Logotyp Black.png";
import EuFunds from "../../../images/EU/EUFunds.png";

const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: 80,
        paddingTop: theme.spacing.xl * 2,
        paddingBottom: theme.spacing.xl * 2,
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
        borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]}`,
    },

    logo: {
        maxWidth: 200,

        [theme.fn.smallerThan("sm")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    },

    description: {
        marginTop: 5,

        [theme.fn.smallerThan("sm")]: {
            marginTop: theme.spacing.xs,
            textAlign: "center",
        },
    },

    inner: {
        display: "flex",
        justifyContent: "space-between",

        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },

    groups: {
        display: "flex",
        flexWrap: "wrap",

        [theme.fn.smallerThan("sm")]: {
            display: "none",
        },
    },

    wrapper: {
        width: 160,
    },

    link: {
        display: "block",
        color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[6],
        fontSize: theme.fontSizes.sm,
        paddingTop: 3,
        paddingBottom: 3,
    },

    title: {
        fontSize: theme.fontSizes.lg,
        fontWeight: 700,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        marginBottom: theme.spacing.xs / 2,
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },

    afterFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing.xl,
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,

        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
        },
    },

    eufunds: {
        width: "38%",
        [theme.fn.smallerThan("sm")]: {
            width: "90%",
        },
    },
}));

const Footer = () => {
    const { classes } = useStyles();

    return (
        <>
            <footer className={classes.footer}>
                <Container className={classes.inner}>
                    <div className={classes.logo}>
                        <img alt="logo" src={Logo} className="h-8" />
                        <Text size="xs" color="dimmed" className={classes.description}>
                            Innovative internet platform streamlining every step of the process of commissioning,
                            creation and delivery of sponsored media content.
                        </Text>
                    </div>
                    <div className={classes.groups}>
                        <div className={classes.wrapper}>
                            <Text className={classes.title}>Company</Text>
                            <Text<"a"> className={classes.link} component="a">
                                Dealfluence Ltd.
                            </Text>
                            <Text<"a"> className={classes.link} component="a">
                                KRS 0000996777
                            </Text>
                            <Text<"a"> className={classes.link} component="a">
                                36-002 Jasionka
                            </Text>
                            <Text<"a"> className={classes.link} component="a">
                                Poland
                            </Text>
                        </div>
                        <div className={classes.wrapper}>
                            <Text className={classes.title}>Website</Text>
                            <Text<"a"> className={classes.link} component="a">
                                About us
                            </Text>
                            <Text<"a"> className={classes.link} component="a">
                                How it works
                            </Text>
                            <Text<"a"> className={classes.link} component="a">
                                Contact
                            </Text>
                            <Text<"a"> className={classes.link} component="a">
                                EU Funds
                            </Text>
                        </div>
                        <div className={classes.wrapper}>
                            <Text className={classes.title}>Contact</Text>
                            <Button>kontakt@dealfluence.com</Button>
                        </div>
                    </div>
                </Container>
                <Container className={classes.afterFooter}>
                    <Text color="dimmed" size="sm">
                        © 2023 Dealfluence Ltd. All rights reserved.
                    </Text>
                    <img src={EuFunds} className={classes.eufunds} />
                </Container>
            </footer>
        </>
    );
};

export default Footer;
