import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider, MultiSelect, NumberInput, Select, Textarea, TextInput } from "@mantine/core";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";

import ContentNieche from "../../../data/ContentNieche";
import ProductType from "../../../data/ProductType";
import { usePostCampaignMutation } from "../../../store/api/campaign/campaign.api";
import Campaign from "../../../store/api/campaign/campaign.types";
import { useGetCountriesQuery } from "../../../store/api/countries/countriesApi";
import GenderDistribution from "../../../types/GenderDistribution";

type Props = {
	campaign: Campaign;
};

const CampaignSchema = Yup.object().shape({
	campaign_name: Yup.string().max(50, "Campaign Name too long.").required("Required"),
	campaign_description: Yup.string().min(20, "Minimum 20 characters").max(5000, "Too Long!").required("Required"),
	ad_type: Yup.string().required("Required"),
	campaign_target: Yup.string().max(2000, "Too long").required("Required"),
	requirements: Yup.string().min(10, "Minimum 10 characters").max(2000, "Too long").required("Required"),
	budget: Yup.number().min(10, "Minimum 10PLN").required("Required"),
	target_audience: Yup.object({
		minAge: Yup.number().min(6, "Too Low!").max(100, "Too high!").required("Required"),
		maxAge: Yup.number().min(Yup.ref("minAge"), "Max Age Has to be higher than min age").max(100, "Too High").required("Required"),
		destinationCountry: Yup.string().required("Required"),
		gender_distribution: Yup.string().required("Required"),
	}),
	content_nieche: Yup.array().min(1, "Pick at least one").required("Required"),
	product_type: Yup.string().required("Required"),
});

const EditLayout = ({ campaign }: Props) => {
	const navigate = useNavigate();
	const { handleSubmit, formState, control } = useForm<Campaign>({
		resolver: yupResolver(CampaignSchema),
		defaultValues: campaign,
	});
	const [postCampaign, { isLoading }] = usePostCampaignMutation();
	const onSubmit = (data: Campaign) => {
		postCampaign(data)
			.unwrap()
			.then(() => {
				navigate("/dashboard/company");
			})
			.catch((err) => {
				toast.error(err.message);
			});
	};
	const countries = useGetCountriesQuery();
	const countriesValues = useMemo(
		() => countries.data?.map((country) => ({ label: country.name || "Unnamed", value: country.id.toString() })) || [],
		[countries]
	);
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="w-full">
			<div className="flex flex-row items-center">
				<div className="flex flex-col mt-2 p-2 w-full">
					<p className="text-2xl font-semibold">Campaign Name:</p>
					<Controller
						name="campaign_name"
						control={control}
						render={({ field }) => (
							<TextInput variant="filled" error={formState.errors["campaign_name"]?.message} {...field} placeholder="Your Campaign name" />
						)}
					/>
					<div className="w-full">
						<div className="min-h-[12rem] mt-5 flex flex-col">
							<p className="text-xl mb-2 font-semibold">Description:</p>

							<Controller
								name="campaign_description"
								control={control}
								render={({ field }) => (
									<Textarea
										placeholder="Describe your campaign"
										error={formState.errors["campaign_description"]?.message}
										{...field}
										variant="filled"
										minRows={8}
										radius="md"
										className="p-1 w-full whitespace-pre-line"
									/>
								)}
							/>
						</div>
					</div>
				</div>
			</div>
			<Divider />
			<div className="flex md:flex-row flex-col md:basis-1/2 p-2">
				<div className="py-3 flex flex-col w-full h-auto">
					<div className=" flex flex-col">
						<p className="text-xl font-semibold mb-2">Campaign Target</p>

						<Controller
							name="campaign_target"
							control={control}
							render={({ field }) => (
								<Textarea
									placeholder="Write a target that your campaign wants to accomplish"
									variant="filled"
									minRows={4}
									error={formState.errors["campaign_target"]?.message}
									{...field}
									radius="md"
									className="p-1 md:w-5/6 w-full whitespace-pre-line"
								/>
							)}
						/>
					</div>
					<div className="my-5 flex flex-col">
						<p className="text-xl font-semibold mb-2">Requirements</p>

						<Controller
							name="requirements"
							control={control}
							render={({ field }) => (
								<Textarea
									placeholder="Write requirements for your campaign"
									variant="filled"
									minRows={4}
									radius="md"
									error={formState.errors["requirements"]?.message}
									{...field}
									className="p-1 md:w-5/6 w-full whitespace-pre-line"
								/>
							)}
						/>
					</div>
					<div className="flex flex-col gap-2 mb-5">
						<p className="text-xl my-2 font-semibold">Budget: </p>
						<Controller
							name="budget"
							control={control}
							render={({ field }) => (
								<NumberInput
									error={formState.errors["budget"]?.message}
									{...field}
									className=" md:w-5/6 w-full"
									placeholder="10000"
									variant="filled"
									step={0.1}
								/>
							)}
						/>
					</div>
					<div className="flex flex-col gap-2">
						<p className="text-xl my-2 font-semibold">Product Type: </p>

						<Controller
							name="product_type"
							control={control}
							render={({ field }) => (
								<Select
									variant="filled"
									placeholder="Physical"
									error={formState.errors["product_type"]?.message}
									{...field}
									className="border-none mb-2 md:w-5/6 w-full"
									data={[
										...Object.keys(ProductType).map((key) => {
											return { value: key, label: Object(ProductType)[key] };
										}),
									]}
								/>
							)}
						/>
					</div>
				</div>
				<div className="py-3 flex flex-col w-full">
					<div className="flex flex-col gap-2 my-2">
						<p className="text-xl font-semibold">Ad Type: </p>
						<Controller
							name="ad_type"
							control={control}
							render={({ field }) => <TextInput error={formState.errors["ad_type"]?.message} {...field} placeholder="Video" variant="filled" />}
						/>
					</div>
					<Divider />
					<div className="flex flex-col gap-2 my-2">
						<p className="text-xl mt-2 font-semibold">Content Nieche</p>
						<Controller
							name="content_nieche"
							control={control}
							render={({ field }) => (
								<MultiSelect
									variant="filled"
									error={formState.errors["content_nieche"]?.message}
									{...field}
									className="border-none mb-2"
									placeholder="Content Type for your Campaign"
									data={[
										...Object.values(ContentNieche).map((value) => {
											return { value: value, label: value };
										}),
									]}
								></MultiSelect>
							)}
						/>
					</div>
					<Divider />
					<p className="text-xl my-2 font-semibold">Target Audience:</p>
					<div className="flex flex-row basis-1/2 w-full gap-5 items-center mb-2">
						<div className="flex flex-col">
							<p className="text-xl my-2">Min Age:</p>
							<Controller
								name="target_audience.minAge"
								control={control}
								render={({ field }) => (
									<NumberInput error={formState.errors["target_audience"]?.minAge?.message} {...field} placeholder="18" variant="filled" step={0.1} />
								)}
							/>
						</div>
						<div className="flex flex-col">
							<p className="text-xl my-2">Max Age:</p>
							<Controller
								name="target_audience.maxAge"
								control={control}
								render={({ field }) => (
									<NumberInput placeholder="60" variant="filled" step={0.1} error={formState.errors["target_audience"]?.maxAge?.message} {...field} />
								)}
							/>
						</div>
					</div>
					<div className="w-full relative mb-3 flex flex-col">
						<p className="text-xl my-2">Destination Country:</p>
						<Controller
							name="target_audience.destinationCountry"
							control={control}
							render={({ field }) => (
								<Select
									searchable
									variant="filled"
									error={formState.errors["target_audience"]?.destinationCountry?.message}
									{...field}
									value={field.value.toString()}
									data={[...countriesValues]}
								></Select>
							)}
						/>
					</div>
					<div className="w-full relative mb-3 flex flex-col">
						<p className="text-xl my-2">Gender Distribution:</p>
						<Controller
							name="target_audience.gender_distribution"
							control={control}
							render={({ field }) => (
								<Select
									variant="filled"
									error={formState.errors["target_audience"]?.gender_distribution?.message}
									{...field}
									placeholder="Male or Female"
									data={[
										...Object.keys(GenderDistribution).map((val) => {
											return { value: val, label: Object(GenderDistribution)[val] };
										}),
									]}
								></Select>
							)}
						/>
					</div>
				</div>
			</div>
			<div className="w-full flex px-2">
				<Button className="ml-auto" type="submit" loading={isLoading}>
					Save
				</Button>
			</div>
		</form>
	);
};

export default EditLayout;
