import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button, PasswordInput, TextInput } from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useSignInMutation } from "../../store/api/auth/authApi.api";
import { toast } from "react-toastify";

interface LoginData {
    email: string;
    password: string;
}
const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Required"),
    password: Yup.string().min(6, "Too Short!").max(33, "Too Long!").required("Required"),
});
// Rozdzielone bo moze byc uzyte w modalu
const Login = () => {
    const [signIn, { isLoading }] = useSignInMutation();
    const { handleSubmit, formState, control } = useForm<LoginData>({
        resolver: yupResolver(LoginSchema),
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const navigate = useNavigate();
    const onSubmit = async (data: LoginData) => {
        signIn(data)
            .unwrap()
            .then(() => {
                toast.success("Logged in successfully!");
                navigate("/");
            })
            .catch((err) => {
                toast.error(err);
            });
    };
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full h-[32rem] max-w-md flex flex-col rounded-xl min-w-sm justify-center shadow-stone-300 py-5 px-16 bg-white text-slate-800"
        >
            <h1 className="text-3xl font-semibold text-center mt-4 mb-6">Welcome Back</h1>
            <div className="w-full relative mb-6">
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextInput
                            label="Email"
                            placeholder="example@mail.com"
                            error={formState.errors["email"]?.message}
                            {...field}
                        />
                    )}
                />
            </div>
            <div className="w-full relative mb-7">
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <PasswordInput
                            autoComplete="current-password"
                            label="Password"
                            placeholder="••••••••"
                            error={formState.errors["password"]?.message}
                            {...field}
                        />
                    )}
                />
            </div>

            <p className="text-sm mb-5 ml-auto">
                <Link to="/password_reset">Forgot password?</Link>
            </p>
            <Button type="submit" radius="md" className="mb-5" loading={isLoading}>
                Sign In
            </Button>
            <p className="text-sm">
                Don't have an account? <Link to="/register">Sign Up</Link>
            </p>
        </form>
    );
};

export default Login;
