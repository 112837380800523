import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAppSelector } from "../store/hooks";
import { selectIsLoggedIn } from "../store/reducers/Auth/Selectors";

const AuthenticatedRoute = () => {
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	return isLoggedIn ? <Outlet /> : <Navigate replace to={"/not_found"} />;
};

export default AuthenticatedRoute;
