import { Select, TextInput } from "@mantine/core";
import React from "react";
import { IconGridDots, IconLayoutRows, IconSearch } from "@tabler/icons";

const SearchBar = () => {
	return (
		<div className="flex flex-row items-center justify-center gap-10 mb-5">
			<TextInput className="flex-1" rightSection={<IconSearch />} radius="md" label="Search Profiles" />
			<Select
				className="flex-1"
				label="Order By"
				data={[
					{ label: "Alphabetical", value: "ALPHABETICAL" },
					{ label: "Newest First", value: "NEWEST" },
				]}
			/>
			<button className="hover:bg-slate-100 p-2 mt-5">
				<IconGridDots />
			</button>

			<button className="hover:bg-slate-100 p-2 mt-5">
				<IconLayoutRows />
			</button>
		</div>
	);
};

export default SearchBar;
