import { Stepper } from "@mantine/core";
import React, { useState } from "react";

import InfluencerInfo from "./InfluencerInfo";
import InformationFilled from "./InformationFilled";
import TargetAudienceInfo from "./TargetAudienceInfo";

type Props = {
	handleChangeType: () => void;
};
const InfluencerStepper = ({ handleChangeType }: Props) => {
	const [active, setActive] = useState(0);
	const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
	const prevStep = () => setActive((current) => (current > -1 ? current - 1 : current));
	if (active === -1) {
		handleChangeType();
	}
	return (
		<>
			<Stepper active={active} breakpoint="sm">
				<Stepper.Step label="First step" description="Something About You">
					<InfluencerInfo handlePrev={prevStep} handleNext={nextStep} />
				</Stepper.Step>
				<Stepper.Step label="Second step" description="Your Target Audience">
					<TargetAudienceInfo handlePrev={prevStep} handleNext={nextStep} />
				</Stepper.Step>
				<Stepper.Completed>
					<InformationFilled handlePrev={prevStep} />
				</Stepper.Completed>
			</Stepper>
		</>
	);
};

export default InfluencerStepper;
