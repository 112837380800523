import { ScrollArea } from "@mantine/core";
import React from "react";
import { Navigate, useParams } from "react-router";

import AcceptedPartnerships from "../Partnership/components/AcceptedPartnerships";
import Chat from "./Components/Chat";

const ChatPage = () => {
    const { id } = useParams();
    if (!id) {
        return <Navigate to={"/"} replace />;
    }
    return (
        <div className="h-[94vh] flex flex-row">
            <ScrollArea className=" w-1/4 border-r h-full p-5 flex flex-col">
                <span className="text-3xl text-medium font-semibold">Browse Partnerships</span>
                <AcceptedPartnerships />
            </ScrollArea>
            <Chat chatId={id} />
        </div>
    );
};

export default ChatPage;
