import { Button } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router";
import Campaign from "../../store/api/campaign/campaign.types";

type Props = {
	campaign: Campaign;
};

const CampaignItem = ({ campaign }: Props) => {
	const navigate = useNavigate();
	return (
		<div className="w-full border-slate-200 border-2 flex flex-row my-2 p-3 rounded-md">
			<div className="flex flex-row justify-center items-center w-full">
				<div className="w-1/3">
					<p className="text-xl font-medium">{campaign.campaign_name}</p>
					<p className="text-md ml-1 max-h-[3em] truncate">{campaign.campaign_description}</p>
				</div>
				<div className="ml-auto">
					<Button onClick={() => navigate("/campaign/" + campaign.id)}>Details</Button>
				</div>
			</div>
		</div>
	);
};

export default CampaignItem;
