import { NumberInput, Textarea } from "@mantine/core";
import React from "react";
import { Control, Controller, FormState } from "react-hook-form";
import Offer from "../../../types/Offer";

type Props = {
    control: Control<Offer, unknown>;
    formState: FormState<Offer>;
};

const OfferForm = ({ control, formState }: Props) => {
    return (
        <>
            <Controller
                name="proposed_pay"
                control={control}
                render={({ field }) => (
                    <NumberInput
                        min={0}
                        step={0.01}
                        precision={2}
                        label="Your Offer"
                        placeholder="Proposed pay"
                        className="p-1 w-full whitespace-pre-line"
                        radius="md"
                        error={formState.errors["proposed_pay"]?.message}
                        {...field}
                    />
                )}
            />
            <Controller
                name="description"
                control={control}
                render={({ field }) => (
                    <Textarea
                        placeholder="Describe your campaign"
                        {...field}
                        label="Offer Description"
                        variant="filled"
                        minRows={6}
                        radius="md"
                        error={formState.errors["description"]?.message}
                        className="p-1 w-full whitespace-pre-line"
                    />
                )}
            />
            <Controller
                name="additional_info"
                control={control}
                render={({ field }) => (
                    <Textarea
                        placeholder="Anything you want to add"
                        {...field}
                        label="Additional Information"
                        variant="filled"
                        minRows={6}
                        error={formState.errors["additional_info"]?.message}
                        radius="md"
                        className="p-1 w-full whitespace-pre-line"
                    />
                )}
            />
        </>
    );
};

export default OfferForm;
