import React from "react";
import Layout from "../../layouts/Layout";
import EditLayout from "./components/EditLayout";
import Campaign from "../../store/api/campaign/campaign.types";

const initialEmptyValues: Campaign = {
	campaign_name: "",
	campaign_description: "",
	ad_type: "",
	budget: 0,
	campaign_target: "",
	requirements: "",
	target_audience: {
		minAge: 6,
		maxAge: 60,
		destinationCountry: "181",
		gender_distribution: "MAINLY_MAN",
	},
	content_nieche: [],
	product_type: "SERVICE",
};
const AddCompaign = () => {
	return (
		<Layout>
			<EditLayout campaign={initialEmptyValues} />
		</Layout>
	);
};

export default AddCompaign;
