import React from "react";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, TextInput, Button } from "@mantine/core";
import { useSignUpToNewsletterMutation } from "../../store/api/newsletter/newsletter.api";
import { toast } from "react-toastify";

interface Newsletter {
    email: string;
    isCompany: boolean;
}

const NewsSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Required"),
    isCompany: Yup.boolean(),
});

const NewsletterWindow = ({ handleClose }: { handleClose: () => void }) => {
    const { handleSubmit, formState, control } = useForm<Newsletter>({
        resolver: yupResolver(NewsSchema),
        defaultValues: {
            email: "",
            isCompany: false,
        },
    });
    const [insertData, { isLoading }] = useSignUpToNewsletterMutation();
    const onSubmit = async (data: Newsletter) => {
        insertData(data)
            .then(() => handleClose())
            .catch((err) => toast.error(err));
    };
    return (
        <form
            className="bg-white mx-5 p-5 flex flex-col items-center justify-center text-center font-normal"
            onSubmit={handleSubmit(onSubmit)}
        >
            <p className="mb-5 font-bold">Sign up for our newsletter!</p>
            <p>We only need to know your e-mail: </p>

            <Controller
                name="email"
                control={control}
                render={({ field }) => (
                    <TextInput
                        placeholder="example@mail.com"
                        className="m-4"
                        error={formState.errors["email"]?.message}
                        {...field}
                    />
                )}
            />

            <Controller
                name="isCompany"
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                    <Checkbox
                        label="I am a company"
                        onBlur={onBlur}
                        error={formState.errors["isCompany"]?.message}
                        onChange={onChange}
                        checked={value}
                        ref={ref}
                    />
                )}
            />
            <p className="mt-2 text-sm">Don't worry, you won't receive any unnecessary spam!</p>
            <Button
                size="sm"
                type="submit"
                className="w-32 h-10 cursor-pointer mt-5 text-white bg-blue-500 rounded-2xl hover:bg-blue-400 z-10"
                loading={isLoading}
            >
                Sign me up!
            </Button>
        </form>
    );
};

export default NewsletterWindow;
