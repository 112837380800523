import { Avatar, Button, Card, createStyles, Group, Text } from "@mantine/core";
import { useNavigate } from "react-router";
import { Influencer } from "../../store/api/campaign/campaign.types";
const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    },

    avatar: {
        border: `2px solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white}`,
    },
}));

type Props = {
    image: string;
    avatar?: string;
    stats: { label: string; value: string }[];
    handleChat?: (chatId: string) => void;
    handleOffer?: (user: Influencer) => void;
    user: Influencer;
};

const UserCard = ({ image, avatar, stats, handleChat, handleOffer, user }: Props) => {
    const { classes, theme } = useStyles();
    const navigate = useNavigate();
    const items = stats.map((stat) => (
        <div key={stat.label}>
            <Text align="center" size="lg" weight={500}>
                {stat.value}
            </Text>
            <Text align="center" size="sm" color="dimmed">
                {stat.label}
            </Text>
        </div>
    ));
    const handleViewProfile = () => {
        navigate("/influencer/" + user.id);
    };
    const handleInteractClick = () => {
        handleOffer && handleOffer(user);
        const chatId = user.user_chat?.at(0)?.chat.id;
        if (!chatId) {
            return;
        }
        handleChat && handleChat(chatId);
    };
    return (
        <Card withBorder p="xl" radius="md" className={classes.card}>
            <Card.Section sx={{ backgroundImage: `url(${image})`, height: 140 }} />
            <Avatar src={avatar} size={80} radius={80} mx="auto" mt={-30} className={classes.avatar} />
            <Text
                onClick={() => handleViewProfile()}
                align="center"
                size="lg"
                weight={500}
                mt="sm"
                className="hover:cursor-pointer"
            >
                {user.influencer_info.name}
            </Text>
            <Text align="center" size="sm" color="dimmed">
                {user.username}
            </Text>
            <Group mt="md" position="center" spacing={30}>
                {items}
            </Group>
            <Button
                onClick={() => handleInteractClick()}
                fullWidth
                radius="md"
                mt="xl"
                size="md"
                color={theme.colorScheme === "dark" ? undefined : "dark"}
            >
                {handleOffer ? "Offer Partnership" : "Message"}
            </Button>
        </Card>
    );
};

export default UserCard;
