import { Button, Divider } from "@mantine/core";
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import Loading from "../../components/loading/Loading";
import ProductType from "../../data/ProductType";
import Layout from "../../layouts/Layout";
import { useLazyGetCampaignByIdQuery } from "../../store/api/campaign/campaign.api";
import GenderDistribution from "../../types/GenderDistribution";

const CampaignPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    // fetch
    const [fetchCampaign, { data: campaign, isLoading, error }] = useLazyGetCampaignByIdQuery();

    React.useEffect(() => {
        if (id) {
            fetchCampaign(id);
        } else {
            navigate("/not_found");
        }
    }, [id]);
    if (isLoading || !campaign) {
        return <Loading />;
    }
    if (error) {
        return <Navigate to={"/not_found"} />;
    }
    return (
        <Layout>
            <div className="flex flex-row items-center mt-2 p-2">
                <p className="text-2xl font-semibold">{campaign.campaign_name}</p>
                <div className="ml-auto">
                    <Button
                        className=""
                        onClick={() => navigate("/campaign/" + id + "/influencers", { state: campaign })}
                    >
                        Manage Influencers
                    </Button>
                    <Button className="ml-5" onClick={() => navigate("/campaign/" + id + "/edit", { state: campaign })}>
                        Edit Compaign
                    </Button>
                </div>
            </div>
            <Divider />
            <div className="flex md:flex-row flex-col md:basis-1/2 p-2">
                <div className="py-3 flex flex-col w-full h-auto">
                    <div className="min-h-[12rem] flex flex-col">
                        <p className="text-xl font-semibold mb-2">Campaign Target</p>
                        <div className="bg-background h-full rounded-lg p-1 md:w-5/6 w-full whitespace-pre-line">
                            {campaign.campaign_target}
                        </div>
                    </div>
                    <div className="min-h-[12rem] mt-5 flex flex-col">
                        <p className="text-xl font-semibold mb-2">Requirements</p>
                        <div className="bg-background h-full rounded-lg p-1 md:w-5/6 w-full">
                            {campaign.requirements}
                        </div>
                    </div>
                </div>
                <div className="py-3 flex flex-col w-full">
                    <div className="flex flex-row gap-2">
                        <p className="text-xl my-2 font-semibold">Product Type: </p>
                        <p className="text-xl my-2">{ProductType[campaign.product_type]}</p>
                    </div>
                    <Divider />
                    <div className="flex flex-row gap-2">
                        <p className="text-xl my-2 font-semibold">Budget: </p>
                        <p className="text-xl my-2">{campaign.budget.toLocaleString("en-US")}</p>
                    </div>
                    <Divider />
                    <div className="flex flex-row gap-2">
                        <p className="text-xl my-2 font-semibold">Ad Type: </p>
                        <p className="text-xl my-2">{campaign.ad_type}</p>
                    </div>
                    <Divider />
                    <p className="text-xl my-2 font-semibold">Content Nieche</p>
                    <div className="flex flex-row mt-2">
                        {campaign.content_nieche.map((nieche) => (
                            <div key={nieche} className="p-2 mr-3 bg-background rounded-lg mb-2">
                                <p className="font-medium">{nieche}</p>
                            </div>
                        ))}
                    </div>
                    <Divider />
                    <p className="text-xl my-2 font-semibold">Target Audience:</p>
                    <div className="flex flex-row basis-1/2 w-full gap-5">
                        <p className="text-xl my-2">Min Age: {campaign.target_audience.minAge}</p>
                        <p className="text-xl my-2">Max Age: {campaign.target_audience.maxAge}</p>
                    </div>
                    <p className="text-xl my-2">Destination Country: {campaign.target_audience.destinationCountry}</p>
                    <p className="text-xl my-2">
                        Gender Distribution: {GenderDistribution[campaign.target_audience.gender_distribution]}
                    </p>
                </div>
            </div>
            <Divider />

            <div className="w-full">
                <div className="min-h-[12rem] mt-5 flex flex-col">
                    <p className="text-xl mb-2 font-semibold">Description:</p>
                    <div className="bg-background rounded-lg p-1 w-full h-[20rem]">{campaign.requirements}</div>
                </div>
            </div>
        </Layout>
    );
};

export default CampaignPage;
