import { createStyles, Text, SimpleGrid, Container, Divider } from "@mantine/core";
import { IconCash, IconUser, IconHeartHandshake } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
    feature: {
        position: "relative",
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
    },

    overlay: {
        position: "absolute",
        height: 150,
        width: 220,
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: "light", color: theme.primaryColor }).background,
        zIndex: 1,
    },

    content: {
        position: "relative",
        zIndex: 2,
    },

    icon: {
        width: "50px",
        height: "50px",
        marginBottom: "20px",
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor }).color,
    },

    title: {
        fontSize: "28px",
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
}));

const Feature = () => {
    const { classes } = useStyles();

    return (
        <>
            <Container mt={50} mb={50} size="lg">
                <SimpleGrid cols={3} breakpoints={[{ maxWidth: "sm", cols: 1 }]} spacing={50}>
                    <div className={classes.feature}>
                        <div className={classes.overlay} />

                        <div className={classes.content}>
                            <IconUser size={38} className={classes.icon} stroke={1.5} />
                            <Text weight={700} size="lg" mb="xs" mt={5} className={classes.title}>
                                1. Work with clients
                            </Text>
                            <Text color="dimmed" size="md">
                                At dealfluence every user has a chance to expand his network of contacts and work
                                together.
                            </Text>
                        </div>
                    </div>
                    <div className={classes.feature}>
                        <div className={classes.overlay} />

                        <div className={classes.content}>
                            <IconHeartHandshake size={38} className={classes.icon} stroke={1.5} />
                            <Text weight={700} size="lg" mb="xs" mt={5} className={classes.title}>
                                2. Collaborate
                            </Text>
                            <Text color="dimmed" size="md">
                                Our system lets both sides secure themselves against fraud and make secure transactions.
                            </Text>
                        </div>
                    </div>
                    <div className={classes.feature}>
                        <div className={classes.overlay} />

                        <div className={classes.content}>
                            <IconCash size={38} className={classes.icon} stroke={1.5} />
                            <Text weight={700} size="lg" mb="xs" mt={5} className={classes.title}>
                                3. Get paid
                            </Text>
                            <Text color="dimmed" size="md">
                                Every dealfluence user has a chance to value his work, which guarantees pay equal to
                                your expectations.
                            </Text>
                        </div>
                    </div>
                </SimpleGrid>
            </Container>
            <Divider mx={210} className="opacity-50" />
        </>
    );
};

export default Feature;
