import { Alert, Anchor, Badge, Center, Modal, Notification, UnstyledButton, Divider, RangeSlider } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "../../../components/loading/Loading";
import { useGetUserQuery } from "../../../store/api/auth/authApi.api";
import { useLazyGetInfluencerInformationByIdQuery } from "../../../store/api/completeInformation/accountInformation.api";
import { useGetCountriesQuery } from "../../../store/api/countries/countriesApi";
import GenderDistribution from "../../../types/GenderDistribution";
import PlatformTypes from "../../../types/PlatformTypes";
import AddPlatform from "./AddPlatform";
import InfluencerAvatar from "./InfluencerAvatar";
import { IconGenderAgender, IconCalendarStats, IconWorld } from "@tabler/icons";

const InfluencerAccount = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: user } = useGetUserQuery();
    const [opened, setOpened] = useState(false);
    const { data: countries } = useGetCountriesQuery();
    const [fetchUser, { data: userInformation, isLoading, error }] = useLazyGetInfluencerInformationByIdQuery();
    useEffect(() => {
        if (id) {
            fetchUser(id);
        }
    }, [id]);
    const userCountry = countries?.find(
        (country) =>
            country.id.toString() === userInformation?.influencer_info.target_audience.destinationCountry.toString()
    );
    if (error) {
        return (
            <p>
                <Alert icon={<IconAlertCircle size={16} />} title="Oops!" color="red">
                    Something went wrong! Try logging in again or reloading your page!
                </Alert>
            </p>
        );
    }
    if (isLoading || !userInformation) return <Loading />;
    return (
        <div className="flex flex-col">
            <div className="flex flex-wrap flex-row justify-center mt-5 gap-10">
                <div className="flex flex-col w-56">
                    <InfluencerAvatar editable={id === user?.id} avatarUrl={userInformation.avatar_url} />
                    <p className="text-4xl font-bold truncate h-12">{userInformation.username}</p>
                </div>
                <div className="flex flex-col px-3">
                    <p className="text-2xl truncate">
                        {userInformation.influencer_info.name} {userInformation.influencer_info.last_name}
                    </p>
                    <div className="border rounded-md p-4 bg-white max-w-[34rem]">
                        <p className="text-2xl font-bold mb-5">My audience</p>
                        <div className="flex flex-col gap-5">
                            <div>
                                <p className="flex opacity-50 justify-between">
                                    Audience Age <IconCalendarStats className="w-5 h-5" />
                                </p>
                                <br />
                                <RangeSlider
                                    disabled={true}
                                    defaultValue={[
                                        userInformation.influencer_info.target_audience.minAge,
                                        userInformation.influencer_info.target_audience.maxAge,
                                    ]}
                                    marks={[
                                        { value: 8, label: "8" },
                                        { value: 16, label: "16" },
                                        { value: 24, label: "24" },
                                        { value: 32, label: "32" },
                                        { value: 40, label: "40" },
                                        { value: 48, label: "48" },
                                        { value: 64, label: "64" },
                                    ]}
                                    max={64}
                                />
                            </div>
                            <div className="flex flex-row mt-5 gap-5">
                                <div>
                                    <p className="flex opacity-50 justify-between">
                                        Gender Distribution <IconGenderAgender className="w-6 h-6" />
                                    </p>
                                    <p className="font-medium">
                                        {
                                            GenderDistribution[
                                                userInformation.influencer_info.target_audience.gender_distribution
                                            ]
                                        }
                                    </p>
                                </div>
                                <div>
                                    <p className="flex opacity-50 justify-between">
                                        Target Country <IconWorld className="w-5 h-5" />
                                    </p>
                                    <p className="font-medium">{userCountry?.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mt-2 gap-2 max-w-[34rem] flex-wrap">
                        {userInformation.influencer_info.content_nieche.map((item, i) => (
                            <Badge key={i} size={"lg"} className="text-xl">
                                {item}
                            </Badge>
                        ))}
                    </div>
                </div>
            </div>
            <section className="mt-5 flex flex-wrap justify-center gap-5 w-inherit mx-auto max-w-[34rem]">
                {userInformation.influencer_info.influencer_profiles.map((item) => (
                    <Anchor key={item.id} href={item.profile_link} target="_blank" underline={false}>
                        <Notification
                            className="mt-2 truncate w-64"
                            disallowClose={true}
                            title={
                                <p className="text-lg truncate">
                                    {PlatformTypes[item.platform_type] + " - " + item.account_name}
                                </p>
                            }
                        >
                            <p className="text-base">
                                ~ {item.avarage_views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} avg. views
                            </p>
                            <p>~ {item.follower_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} followers</p>
                        </Notification>
                    </Anchor>
                ))}
            </section>
        </div>
    );
};
export default InfluencerAccount;
