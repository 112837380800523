const PlatformTypes = {
  TIKTOK: "Tik Tok",
  YOUTUBE: "YouTube",
  TWITTER: "Twitter",
  TELEGRAM: "Telegram",
  DISCORD: "Discord",
  PINTEREST: "Pinterest",
  BLOG: "Blogs",
  PODCAST: "Podcasts",
  TWITCH: "Twitch",
  INSTAGRAM: "Instagram",
  SNAPCHAT: "Snapchat",
};
export default PlatformTypes;
