import supabaseApi from "..";
import { supabase } from "../../../supabase";

const countriesApi = supabaseApi.injectEndpoints({
    endpoints: (builder) => ({
        // supabase rtk query enpoints for authentication
        signUpToNewsletter: builder.mutation<null, { email: string; isCompany: boolean }>({
            queryFn: async ({ email, isCompany }) => {
                const { error } = await supabase.from("newsletter").insert({ email: email, isCompany: isCompany });
                if (error) {
                    return { error: error?.message };
                }
                return { data: null };
            },
        }),
        contactUs: builder.mutation<null, { email: string; message: string; full_name: string }>({
            queryFn: async ({ email, message, full_name }) => {
                const { error } = await supabase
                    .from("message_us")
                    .insert({ email: email, message: message, full_name: full_name });
                if (error) {
                    return { error: error?.message };
                }
                return { data: null };
            },
        }),
    }),
});
export const { useSignUpToNewsletterMutation, useContactUsMutation } = countriesApi;
export { countriesApi };
