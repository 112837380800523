import React from "react";
import ResetPassword from "../../components/authorization/ResetPassword";

const PasswordResetPage = () => {
	return (
		<div className="w-full h-full overflow-hidden flex justify-center pt-20">
			<ResetPassword />
		</div>
	);
};

export default PasswordResetPage;
