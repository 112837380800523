const ContentNieche = {
    NEWS: "News",
    POLITICS: "Politics",
    GAMING: "Gaming",
    MEMES_AND_ENTERTAINMENT: "Memes & Entertainment",
    FINANCIAL_AND_BUSINESS: "Financial & Business",
    SELF_IMPROVEMENT: "Self Improvement",
    FINTESS: "Fitness",
    BEAUTY: "Beauty",
    LIFESTYLE: "Lifestyle",
    INTERVIEWS: "Interviews",
    REACTING: "Reacting",
    MOTIVATIONAL: "Motivational",
    MUSICAL: "Musical",
    REVIEWS: "Reviews",
    COMENTARY: "Comentary",
    EDUCATIONAL: "Educational",
    PHOTOGRAPHY_AND_FILM_MAKING: "Photography & Filmmaking",
    CARS: "Cars",
    FASHION_AND_CLOTHING: "Fashion & Clothing",
    TECH_AND_GADGETS: "Tech & Gadgets",
    COOKING: "Cooking",
};
export default ContentNieche;
// For now, later maybe turn this into an object for easier api calls
