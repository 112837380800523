import React from "react";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Group, TextInput, Button, Textarea, Divider } from "@mantine/core";
import { forwardRef } from "react";
import { useContactUsMutation } from "../../../store/api/newsletter/newsletter.api";
import { toast } from "react-toastify";

interface Contact {
    name: string;
    email: string;
    message: string;
}

const ContactSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").max(33, "Too Long!").required("Required"),
    email: Yup.string()
        .email("Please enter a valid email")
        .min(2, "Too Short!")
        .max(33, "Too Long!")
        .required("Required"),
    message: Yup.string().min(2, "Too Short!").max(1400, "Too Long!").required("Required"),
});

const Contact = forwardRef<HTMLDivElement>((props, ref) => {
    const { handleSubmit, formState, control, reset } = useForm<Contact>({
        resolver: yupResolver(ContactSchema),
        defaultValues: {
            name: "",
            email: "",
            message: "",
        },
    });
    const [insertData, { isLoading }] = useContactUsMutation();
    const onSubmit = (data: Contact) => {
        insertData({ ...data, full_name: data.name })
            .then(() => {
                toast.info("Message sent! Expect us to contact you soon!");
                reset();
            })
            .catch((err) => toast.error(err));
    };

    return (
        <>
            <div className="mt-20" ref={ref}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className="text-3xl font-bold text-center">Reach out to us!</p>
                    <p className="text-xl mt-5 text-center">
                        Our team will contact you within 12 hours in order to discuss
                        <br />
                        details and provide any relevant information.
                    </p>
                    <Group position="center" className="mt-10">
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <TextInput
                                    withAsterisk
                                    label="Name & surname"
                                    placeholder="Name & surname"
                                    className="w-[33rem] mx-2"
                                    error={formState.errors["name"]?.message}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <TextInput
                                    withAsterisk
                                    label="E-mail Address"
                                    placeholder="E-mail Address"
                                    className="w-[33rem] mx-2"
                                    error={formState.errors["email"]?.message}
                                    {...field}
                                />
                            )}
                        />
                    </Group>
                    <div className="flex justify-center px-2 mt-2">
                        <Controller
                            name="message"
                            control={control}
                            render={({ field }) => (
                                <Textarea
                                    className="w-full max-w-[68rem]"
                                    minRows={10}
                                    withAsterisk
                                    label="Your message"
                                    placeholder="Your message"
                                    error={formState.errors["message"]?.message}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <Group position="center">
                        <Button loading={isLoading} type="submit" className="my-5">
                            Send message
                        </Button>
                    </Group>
                </form>
            </div>
        </>
    );
});

export default Contact;
