import { createClient } from "@supabase/supabase-js";

export const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
export const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
if (!supabaseUrl) {
    throw new Error("REACT_APP_SUPABASE_URL is not set");
}
if (!supabaseAnonKey) {
    throw new Error("REACT_APP_SUPABASE_ANON_KEY is not set");
}
export const supabase = createClient(supabaseUrl || "", supabaseAnonKey || "");
